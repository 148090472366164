import React, { useState, useEffect } from "react";
import {
  Card,
  Input,
  Space,
  Button,
  Row,
  Col,
  message,
  Steps,
  Spin,
  Checkbox,
  Typography,
} from "antd";
import {
  UserOutlined,
  BlockOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { api } from "../../config/api";
import { isEmailValid, isPasswordSafe } from "../../helpers/validators";
import { Link } from "react-router-dom";

const { Text } = Typography;

const RegisterPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [regulationsAccepted, setRegulationsAccepted] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [savedDeviceData, setSavedDeviceData] = useState(1);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    setSavedDeviceData({ type: 1 });
  }, []);

  const getDeviceIcon = (deviceType) => {
    switch (deviceType) {
      case 1:
        return (
          <img
            src="/img/mobile-phone.png"
            alt="Mobile"
            style={{ width: 64, height: 64 }}
          />
        );
      case 2:
        return (
          <img
            src="/img/tablet.png"
            alt="Tablet"
            style={{ width: 64, height: 64 }}
          />
        );
      case 3:
        return (
          <img
            src="/img/laptop.png"
            alt="Laptop"
            style={{ width: 64, height: 64 }}
          />
        );
      case 4:
      default:
        return (
          <img
            src="/img/pendrive.png"
            alt="Other"
            style={{ width: 64, height: 64 }}
          />
        );
    }
  };

  const steps = [
    {
      title: "Utwórz konto",
    },
    {
      title: "Potwierdź e-mail",
    },
    {
      title: "Dodaj urządzenia",
    },
  ];

  const handleRegulations = (e) => {
    setRegulationsAccepted(e.target.checked);
  };

  const handleRegister = () => {
    if (isEmailValid(email) !== true) {
      messageApi.open({
        type: "error",
        content: "Adres e-mail nie jest poprawny.",
      });
      return;
    }

    if (password !== confirmPassword) {
      messageApi.open({
        type: "error",
        content: "Hasła nie pasują do siebie. Spróbuj ponownie.",
      });
      return;
    }

    if (!isPasswordSafe(password)) {
      messageApi.open({
        type: "error",
        content:
          "Hasło musi mieć co najmniej 8 znaków i zawierać przynajmniej jedną wielką literę, jedną cyfrę i jeden znak specjalny (!@#$%^&*).",
      });
      return;
    }

    if (!regulationsAccepted) {
      messageApi.open({
        type: "error",
        content: "Musisz zaakceptować postanowienia regulaminu usługi.",
      });
      return;
    }

    setLoading(true);
    const apiUrl = `${api.host}/accounts`;

    const requestBody = {
      email,
      password,
    };

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (response.status === 201) {
          messageApi.open({
            type: "success",
            content: "Konto zostało założone. Potwierdź podany e-mail.",
          });
          setCurrentStep(1);
        } else {
          messageApi.open({
            type: "error",
            content: "Na podany adres e-mail jest już zarejestrowane konto.",
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        messageApi.open({
          type: "error",
          content: "Na podany adres e-mail jest już zarejestrowane konto.",
        });
      });
  };

  const handleVerification = () => {
    if (code === "") {
      messageApi.open({
        type: "error",
        content: "Nie przepisałeś kodu weryfikacyjnego.",
      });
      return;
    }

    const apiUrl = `${api.host}/accounts/email-confirmation`;
    setLoading(true);

    const requestBody = {
      email,
      code,
    };

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (response.status === 200) {
          messageApi.open({
            type: "success",
            content: "Konto zostało zweryfikowane.",
          });
          setCurrentStep(1);
          setTimeout(() => {
            window.location.href = "/devices";
          }, 2000);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        messageApi.open({
          type: "error",
          content: "Niepoprawny kod weryfikacyjny. Spróbuj jeszcze raz.",
        });
      });
  };

  return (
    <Row
      style={{
        justifyContent: "center",
      }}
    >
      {contextHolder}
      <Col
        style={{
          maxWidth: "350px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
          marginTop: "20px",
        }}
      >
        <Space size={30} direction="vertical">
          <Space size={10}>
            <h2 className="offer">Dobry wybór! Witaj w</h2>
            <h1 className="offer">CELLID</h1>
          </Space>

          {savedDeviceData && (
            <Card>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: "10px" }}>
                  {getDeviceIcon(savedDeviceData.type)}
                </div>
                <Text level={4}>
                  {savedDeviceData.type === 1
                    ? "Telefon"
                    : savedDeviceData.type === 2
                    ? "Tablet"
                    : savedDeviceData.type === 3
                    ? "Laptop"
                    : "Inne"}{" "}
                  to cenna rzecz, a dane zapisane na nim są jeszcze cenniejsze.
                  Jeszcze tylko krok dzieli Cię od pełnego zabezpieczenia przed utratą kontaktu do Ciebie w
                  razie jego zguby.
                </Text>
              </div>
            </Card>
          )}

          <Spin spinning={loading}>
            <Card
              title={
                <>
                  <img src="/img/devices.png" /> Nowe Konto
                </>
              }
              extra={
                <Link to="/">
                  <CloseCircleOutlined style={{ color: "#8c8c8c" }} />
                </Link>
              }
              style={{
                boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
              }}
            >
              <Space size={30} direction="vertical">
                <Steps size="small" current={currentStep} items={steps}></Steps>

                {currentStep === 0 ? (
                  <>
                    <Input
                      size="large"
                      autoComplete="off"
                      placeholder="Adres e-mail"
                      prefix={<UserOutlined />}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />

                    <Input.Password
                      size="large"
                      autoComplete="off"
                      placeholder="Hasło"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />

                    <Input.Password
                      size="large"
                      autoComplete="off"
                      placeholder="Powtórz hasło"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />

                    <Checkbox onChange={handleRegulations}>
                      Potwierdzam, że zapoznałem/zapoznałam się z{" "}
                      <Link to="/regulations">regulaminem usługi Cell ID</Link>{" "}
                      oraz <Link to="/privacy">polityką prywatności</Link> i
                      akceptuję warunki.
                    </Checkbox>

                    <Button type="primary" onClick={handleRegister}>
                      Utwórz darmowe konto
                    </Button>
                  </>
                ) : (
                  <>
                    <p>
                      Przepisz otrzymany kod weryfikacyjny, który wysłaliśmy na
                      podany adres e-mail.
                    </p>
                    <Input
                      size="large"
                      autoComplete="off"
                      placeholder="Kod weryfikacyjny"
                      prefix={<BlockOutlined />}
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                    />
                    <Button type="primary" onClick={handleVerification}>
                      Zweryfikuj adres e-mail
                    </Button>
                  </>
                )}
              </Space>
            </Card>
          </Spin>
        </Space>
      </Col>
    </Row>
  );
};

export default RegisterPage;
