import React from "react";
import { Table } from "antd";

const columns = [
  {
    title: "Kryterium",
    dataIndex: "kryterium",
    key: "kryterium",
  },
  {
    title: "CellID (QR kod na tapecie)",
    dataIndex: "cellid",
    key: "cellid",
  },
  {
    title: "Family Link",
    dataIndex: "familyLink",
    key: "familyLink",
  },
  {
    title: "Naklejka z numerem telefonu",
    dataIndex: "naklejka",
    key: "naklejka",
  },
  {
    title: "Numer ratunkowy (iPhone)",
    dataIndex: "numerRatunkowy",
    key: "numerRatunkowy",
  },
  {
    title: "Wiadomość na ekranie blokady (Android/iOS)",
    dataIndex: "wiadomosc",
    key: "wiadomosc",
  },
];

const data = [
  {
    key: "1",
    kryterium: "Łatwość użycia",
    cellid: "Wystarczy zeskanować QR kod, aby uzyskać dane kontaktowe",
    familyLink: "Działa do momentu wyczerpania internetu",
    naklejka: "Prosta, ale może się uszkodzić",
    numerRatunkowy: "Widoczny i łatwo dostępny",
    wiadomosc: "Prosta do ustawienia, ale ograniczone miejsce",
  },
  {
    key: "8",
    kryterium: "Powiadomenia SMS o odnalezionym urządzeniu",
    cellid: "Tak",
    familyLink: "Brak tej funkcji",
    naklejka: "Brak tej funkcji",
    numerRatunkowy: "Brak tej funkcji",
    wiadomosc: "Brak tej funkcji",
  },
  {
    key: "9",
    kryterium: "Powiadomenia E-MAIL o odnalezionym urządzeniu",
    cellid: "Tak",
    familyLink: "Brak tej funkcji",
    naklejka: "Brak tej funkcji",
    numerRatunkowy: "Brak tej funkcji",
    wiadomosc: "Brak tej funkcji",
  },
  {
    key: "2",
    kryterium: "Aktualizacja danych",
    cellid: "Możliwość zdalnej aktualizacji wizytówki",
    familyLink: "Brak tej funkcji",
    naklejka: "Wymaga fizycznej wymiany naklejki",
    numerRatunkowy: "Wymaga ręcznej aktualizacji",
    wiadomosc: "Wymaga ręcznej aktualizacji",
  },
  {
    key: "3",
    kryterium: "Bezpieczeństwo",
    cellid: "Dane kontaktowe widoczne dopiero po zeskanowaniu kodu QR",
    familyLink: "Brak dodatkowych funkcji zabezpieczających",
    naklejka: "Nie oferuje zabezpieczeń",
    numerRatunkowy: "Dane widoczne tylko w trybie ratunkowym",
    wiadomosc: "Dane jawnie widoczne na ekranie blokady",
  },
  {
    key: "4",
    kryterium: "Elastyczność",
    cellid: "Możliwość dostosowania zawartości QR kodu",
    familyLink: "Ograniczone funkcje",
    naklejka: "Brak elastyczności",
    numerRatunkowy: "Ograniczone do numeru ratunkowego",
    wiadomosc: "Ograniczone do tekstu",
  },
  {
    key: "5",
    kryterium: "Zastosowanie",
    cellid: "Indywidualne i firmowe, szczególnie przy wielu urządzeniach",
    familyLink: "Głównie rodzice kontrolujący dzieci",
    naklejka: "Głównie indywidualne",
    numerRatunkowy: "Indywidualne",
    wiadomosc: "Indywidualne",
  },
  {
    key: "6",
    kryterium: "Cena",
    cellid: "Freemium model: darmowa wersja z opcją płatnych funkcji",
    familyLink: "Darmowe, wymaga dostępu do internetu",
    naklejka: "Koszt naklejki",
    numerRatunkowy: "Darmowe",
    wiadomosc: "Darmowe",
  },
  {
    key: "7",
    kryterium: "Dostępność",
    cellid: "Nie wymaga dodatkowych aplikacji",
    familyLink: "Wymaga Google Family Link",
    naklejka: "Powszechnie dostępna",
    numerRatunkowy: "Wbudowane w iOS",
    wiadomosc: "Wbudowane w Android/iOS",
  },
];

const ComparisonTable = () => (
  <div style={{ overflowX: "auto" }}>
    <center>&larr; <small>Przewiń w bok</small> &rarr;</center>
    <Table style={{marginTop:"10px"}} columns={columns} dataSource={data} pagination={false} />
  </div>
);

export default ComparisonTable;
