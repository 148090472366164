import React, { useState, useEffect } from "react";
import LoginPage from "../Login/LoginPage";
import {
  Card,
  Space,
  Row,
  Col,
  message,
  Button,
  Modal,
  Tooltip,
  List,
} from "antd";
import {
  isLogged,
  getAuthToken,
  destroyAuthToken,
} from "../../helpers/account";
import { api } from "../../config/api";
import { useSelector, useDispatch } from "react-redux";
import NewDeviceForm from "../Device/Forms/NewDeviceForm";
import QRCodePopup from "../Device/Qr/QrCodePopup";
import CurrentPlanInfo from "./Account/Plan/CurrentPlanInfo";
import { FieldTimeOutlined } from "@ant-design/icons";
import { formatPolishDate } from "../../helpers/date";
import LocationPopup from "../Device/Location/LocationPopup";
import EditDeviceForm from "../Device/Forms/EditDeviceForm";
import {
  AimOutlined,
  CompassOutlined,
  FileImageOutlined,
} from "@ant-design/icons";

import LocationAllPopup from "../Device/Location/LocationAllPopup";

const shortenName = (name) => {
  if (name.length > 50) {
    return name.substring(0, 20) + "...";
  }
  return name;
};

const questions = [
  {
    key: "1",
    label:
      "Korzystam z pakietu Free - jeśli przejdę na pakiet płatny to kod QR ulegnie zmianie?",
    content: (
      <p>
        Tak, kod ulegnie zmianie. Kody premium pochodzą z osobnej puli, którą
        przypiszemy do Twojego konta.
      </p>
    ),
  },
  {
    key: "2",
    label:
      "Czy mogę nakleić kod QR na inne urządzenie niż był naklejony pierwotnie?",
    content: (
      <p>
        Tak, jako użytkownik wybranego pakietu masz dostęp do puli kodów QR,
        które są przypisane do Twojego konta. Gdy dodajesz nowe urządzenie,
        otrzymuje ono kod z tej puli. Jeśli usuniesz urządzenie, jego kod wraca
        do puli i może być użyty ponownie przez inne urządzenie dodane w Twoim
        koncie.
      </p>
    ),
  },
];

const getDeviceIcon = (deviceType) => {
  switch (deviceType) {
    case 1:
      return (
        <img
          src="/img/mobile-phone.png"
          alt="Mobile"
          style={{ width: 24, height: 24 }}
        />
      );
    case 2:
      return (
        <img
          src="/img/tablet.png"
          alt="Tablet"
          style={{ width: 24, height: 24 }}
        />
      );
    case 3:
      return (
        <img
          src="/img/laptop.png"
          alt="Laptop"
          style={{ width: 24, height: 24 }}
        />
      );
    case 4:
    default:
      return (
        <img
          src="/img/pendrive.png"
          alt="Other"
          style={{ width: 24, height: 24 }}
        />
      );
  }
};

const DevicesPage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resources, setResources] = useState([]);
  const [qrPopupVisible, setQrPopupVisible] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [deviceData, setDeviceData] = useState(null);
  const [prefilledDeviceData, setPrefilledDeviceData] = useState(null); // New state for prefilled device data
  const [visible, setVisible] = useState(null);
  const [locationPopupVisible, setLocationPopupVisible] = useState(false); // State for location popup
  const [locationAllPopupVisible, setLocationAllPopupVisible] = useState(false); // State for location popup

  const transactionDataChanged = useSelector(
    (state) => state.transactionDataChanged
  );

  const dispatch = useDispatch();

  const transactionCreated = useSelector((state) => state.transactionCreated);

  const handleLocationClick = async (device) => {
    setLocationPopupVisible(true);
    setDeviceData(device);
  };

  const getDevice = async (uuid) => {
    try {
      const response = await fetch(`${api.host}/devices/${uuid}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      });
      const device = await response.json();
      handleQrCodeClick(device);
    } catch (error) {
      console.error("Error fetching device data:", error);
    }
  };

  const handleLocationAllClick = async () => {
    setLocationAllPopupVisible(true);
  };

  const handleWhereIsClick = async (device) => {
    try {
      const response = await fetch(
        `${api.host}/devices/whereis/${device.uuid}`,
        {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `${getAuthToken()}`,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Błąd podczas wysyłania SMS");
      }

      message.success("Wysłaliśmy SMS na lokalizowany numer.");
    } catch (error) {
      message.error("Błąd podczas wysyłania SMS.");
    }
  };

  const handleQrCodeClick = (device) => {
    setQrCodeUrl(`https://cellid.pl/device/${device.uuid}`);
    setDeviceData(device);
    setQrPopupVisible(true);
  };

  useEffect(() => {
    const fetchResources = async () => {
      try {
        const apiUrl = `${api.host}/accounts/plans/resources`;
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `${getAuthToken()}`,
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to fetch resources");
        }

        const data = await response.json();
        setResources(data.resources);
        setLoading(false);
      } catch (err) {
        message.error("Sesja wygasła, zostałeś wylogowany.");
        destroyAuthToken();
        setLoading(false);
      }
    };
    if (isLogged()) {
      fetchResources();
    }
  }, []);

  useEffect(() => {
    const apiUrl = `${api.host}/devices`;

    if (sessionStorage.getItem("authToken")) {
      fetch(apiUrl, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `${getAuthToken()}`,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setData(data);
          setLoading(false);
        })
        .catch((error) => {
          setTimeout(() => {
            message.error("Dla bezpieczeństwa zostałeś wylogowany.");
            destroyAuthToken();
            window.location.href = "/devices";
          }, 2000);
          setLoading(false);
        });

      if (transactionCreated) {
        getDevice(transactionCreated);
        dispatch({
          type: "transactionCreated",
          payload: {
            transactionCreated: false,
          },
        });
      }
    }
  }, [transactionDataChanged, transactionCreated]);

  useEffect(() => {
    const deviceData = JSON.parse(localStorage.getItem("deviceData"));
    if (deviceData) {
      setPrefilledDeviceData(deviceData);
    } else {
      setPrefilledDeviceData(null);
    }
  }, [transactionCreated]);

  if (isLogged()) {
    return (
      <>
        <Row
          style={{
            minHeight: "100vh",
            marginTop: "30px",
            marginBottom: "30px",
          }}
        >
          <Col
            span={24}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <Space direction="vertical" size={30} style={{ minWidth: "350px" }}>
              <Card
                title={
                  <>
                    {" "}
                    <img src="/img/devices.png" alt="Moje urządzenia" /> Moje
                    urządzenia
                  </>
                }
              >
                <Space size={30} direction="vertical" style={{ width: "100%" }}>
                  <Space size={167}>
                    <NewDeviceForm prefilledData={prefilledDeviceData} />
                  </Space>
                  <List
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={(item, index) => (
                      <List.Item
                        actions={[
                          index === 0 ? (
                            <Tooltip
                              title={`QR`}
                              defaultOpen={true}
                              arrow={false}
                            >
                              <Button
                                size="small"
                                type="link"
                                onClick={() => handleQrCodeClick(item)}
                              >
                                <FileImageOutlined />
                              </Button>
                            </Tooltip>
                          ) : (
                            <Button
                              size="small"
                              type="link"
                              onClick={() => handleQrCodeClick(item)}
                            >
                              <FileImageOutlined />
                            </Button>
                          ),
                          index === 0 ? (
                            <Tooltip
                              title={`Mapa`}
                              defaultOpen={true}
                              arrow={false}
                            >
                              <Button
                                size="small"
                                type="link"
                                onClick={() => handleLocationClick(item)}
                              >
                                <CompassOutlined />
                              </Button>
                            </Tooltip>
                          ) : (
                            <Button
                              size="small"
                              type="link"
                              onClick={() => handleLocationClick(item)}
                            >
                              <CompassOutlined />
                            </Button>
                          ),
                        ]}
                      >
                        <List.Item.Meta
                          avatar={getDeviceIcon(item.device_type_id)}
                          title={
                            <>
                              <Space>
                                <EditDeviceForm
                                  deviceUuid={item.uuid}
                                  deviceName={shortenName(item.name)}
                                />
                                {item.qr_remotely_created_at ? (
                                  <>
                                    {" "}
                                    <Tooltip
                                      title={`Ostatnia zdalna aktualizacja tapety: ${formatPolishDate(
                                        item.qr_remotely_created_at
                                      )}`}
                                    >
                                      <FieldTimeOutlined />
                                    </Tooltip>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Space>
                            </>
                          }
                        />
                      </List.Item>
                    )}
                    loading={loading}
                    locale={{
                      emptyText: "Jeszcze nie dodałeś żadnego urządzenia.",
                    }}
                  />
                </Space>
              </Card>
              <CurrentPlanInfo />
              <div className="faq">
                <h3>Najczęściej zadawane pytania</h3>
              </div>
              <div className="questions">
                {questions.map((question) => (
                  <div key={question.key} style={{ marginBottom: "16px" }}>
                    <a
                      href="#"
                      style={{ width: "300px", overflowX: "hidden" }}
                      onClick={(e) => {
                        e.preventDefault();
                        setVisible(question.key);
                      }}
                    >
                      {question.label}
                    </a>
                    <Modal
                      title={question.label}
                      visible={visible === question.key}
                      onCancel={() => setVisible(null)}
                      footer={[
                        <Button key="close" onClick={() => setVisible(null)}>
                          Zamknij
                        </Button>,
                      ]}
                    >
                      {question.content}
                    </Modal>
                  </div>
                ))}
              </div>
            </Space>
          </Col>
        </Row>
        <QRCodePopup
          visible={qrPopupVisible}
          onClose={() => setQrPopupVisible(false)}
          qrCodeUrl={qrCodeUrl}
          device={deviceData}
        />
        <LocationPopup
          visible={locationPopupVisible}
          onClose={() => setLocationPopupVisible(false)}
          device={deviceData}
        />
        <LocationAllPopup
          visible={locationAllPopupVisible}
          onClose={() => setLocationAllPopupVisible(false)}
        />
      </>
    );
  } else {
    return <LoginPage />;
  }
};

export default DevicesPage;
