import React, { useState, useEffect } from "react";
import {
  Space,
  Button,
  Modal,
  Input,
  message,
  Spin,
  Select,
  Alert,
  Checkbox,
} from "antd";
import { useDispatch } from "react-redux";
import { isLogged, getAuthToken } from "../../../helpers/account";
import { api } from "../../../config/api";
import { MailOutlined, MobileOutlined, BellOutlined, AimOutlined } from "@ant-design/icons";

const { TextArea } = Input;
const { Option } = Select;

const types = [
  { value: 1, label: "Telefon" },
  { value: 2, label: "Tablet" },
  { value: 3, label: "Laptop" },
  { value: 4, label: "Inne" },
];

const EditDeviceForm = ({ deviceUuid, deviceName }) => {
  const [editDeviceModalOpen, setEditDeviceModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [planInfo, setPlanInfo] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [formData, setFormData] = useState({
    name: "",
    message: "",
    type: "",
    mobile: "",
    origin_mobile: "",
    email: "",
    is_email_notification_enabled: false,
    is_sms_notification_enabled: false,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchCurrentPlan = async () => {
      try {
        const apiUrl = `${api.host}/accounts/plans/current`;
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `${getAuthToken()}`,
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to fetch current plan");
        }

        const data = await response.json();
        setPlanInfo(data.currentPlan);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching current plan:", error);
      }
    };

    fetchCurrentPlan();
  }, [editDeviceModalOpen]);

  const fetchDeviceData = async () => {
    if (isLogged()) {
      setLoading(true);
      try {
        const response = await fetch(`${api.host}/devices/${deviceUuid}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        });
        const data = await response.json();
        setFormData({
          name: data.name,
          message: data.message,
          type: data.device_type_id,
          mobile: data.mobile,
          origin_mobile: data.origin_mobile,
          email: data.email,
          is_email_notification_enabled: data.is_email_notification_enabled,
          is_sms_notification_enabled: data.is_sms_notification_enabled,
        });
      } catch (error) {
        console.error("Error fetching device data:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (editDeviceModalOpen) {
      fetchDeviceData();
    }
  }, [editDeviceModalOpen]);

  const updateDevice = async () => {
    if (!validateFormData()) return;

    setLoading(true);
    try {
      const response = await fetch(`${api.host}/devices/${deviceUuid}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
        body: JSON.stringify({
          name: formData.name,
          message: formData.message,
          device_type_id: formData.type,
          email: formData.email,
          mobile: formData.mobile.replace(/\D/g, ""),
          origin_mobile: formData.origin_mobile.replace(/\D/g, ""),
          is_email_notification_enabled: formData.is_email_notification_enabled,
          is_sms_notification_enabled: formData.is_sms_notification_enabled,
        }),
      });
      await response.json();
      message.success("Urządzenie zostało zaktualizowane.");
      dispatchUpdateActions();
      setEditDeviceModalOpen(false);
    } catch (error) {
      console.error("Error updating device:", error);
    } finally {
      setLoading(false);
    }
  };

  const confirmDeleteDevice = () => {
    Modal.confirm({
      title: "Czy na pewno chcesz usunąć to urządzenie?",
      content:
        "Usunięcie urządzenia zwróci przypisany kod QR do puli kodów Twojego konta. Zwolniony kod QR zostanie ponownie wykorzystany przy dodawaniu nowego urządzenia.",
      okText: "Usuń",
      cancelText: "Anuluj",
      onOk: deleteDevice,
    });
  };

  const deleteDevice = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${api.host}/devices/${deviceUuid}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      });
      if (response.ok) {
        message.success("Urządzenie zostało usunięte.");
        dispatchUpdateActions();
        setEditDeviceModalOpen(false);
      } else {
        throw new Error("Failed to delete device");
      }
    } catch (error) {
      console.error("Error deleting device:", error);
    } finally {
      setLoading(false);
    }
  };

  const dispatchUpdateActions = () => {
    dispatch({
      type: "transactionDataUpdated",
      payload: { transactionDataChanged: new Date().getSeconds() },
    });
    dispatch({
      type: "transactionUpdated",
      payload: { transactionUpdated: true },
    });
  };

  const validateFormData = () => {
    if (!formData.name || !formData.message || !formData.type) {
      message.error("Wszystkie pola są wymagane.");
      return false;
    }
    return true;
  };

  return (
    <>
      {contextHolder}
      <Button
        onClick={() => setEditDeviceModalOpen(true)}
        type="default"
        size="small"
      >
        {deviceName}
      </Button>

      <Modal
        title="Edytuj urządzenie"
        open={editDeviceModalOpen}
        onOk={updateDevice}
        onCancel={() => setEditDeviceModalOpen(false)}
        okText="Zaktualizuj"
        cancelText="Anuluj"
        footer={[
          <Button key="delete" type="link" onClick={confirmDeleteDevice}>
            Usuń
          </Button>,
          <Button key="cancel" onClick={() => setEditDeviceModalOpen(false)}>
            Anuluj
          </Button>,
          <Button key="submit" type="primary" onClick={updateDevice}>
            Zaktualizuj
          </Button>,
        ]}
      >
        <Spin spinning={loading}>
          <Space size={15} direction="vertical" style={{ width: "100%" }}>
            <p>
              <Space>
                Wyślij link: https://cellid.pl/....={deviceUuid}{" "}
                <Button
                size="small"
                onClick={() => {
                  navigator.clipboard.writeText(`https://cellid.pl/autoconfig?deviceKey=${deviceUuid}`);
                  messageApi.open({
                    type: "success",
                    content: "Skopiowane do schowka.",
                  });
                }}
              >
                Kopiuj
              </Button>
              </Space>
            </p>
            <Alert
              type="info"
              banner={true}
              description="Modyfikacje nie wpływają na przypisany kod QR, więc nie musisz się martwić, że kod przestanie działać."
            />
            <Input
              placeholder="Nazwa urządzenia"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
            />
            <Input
              prefix={<MobileOutlined />}
              placeholder="Numer telefonu do kontaktu w razie zguby"
              onChange={(e) =>
                setFormData({
                  ...formData,
                  mobile: e.target.value,
                })
              }
              value={formData.mobile}
            />
            <Input
              prefix={<MailOutlined />}
              placeholder="Adres e-mail do kontaktu w razie zguby"
              onChange={(e) =>
                setFormData({
                  ...formData,
                  email: e.target.value,
                })
              }
              value={formData.email}
            />
            <TextArea
              placeholder="Wiadomość dla znalazcy"
              rows={4}
              value={formData.message}
              onChange={(e) =>
                setFormData({ ...formData, message: e.target.value })
              }
            />
            <Select
              placeholder="Wybierz typ urządzenia"
              value={formData.type}
              onChange={(value) => setFormData({ ...formData, type: value })}
              style={{ width: "100%" }}
            >
              {types.map((device) => (
                <Option key={device.value} value={device.value}>
                  {device.label}
                </Option>
              ))}
            </Select>
            <Space direction="vertical">
              <h3 style={{ marginBottom: 0 }}>
                <AimOutlined /> Funkcja "Gdzie jesteś"
              </h3>
              <p style={{ marginTop: 0 }}>
                Funkcja pozwala na otrzymanie wiadomości SMS z zagubionego
                telefonu z jego lokalizacją. Jest to przydatne w przypadku braku
                Internetu w zagubionym telefonie. Wiadomość zostanie wysyłane na
                numer telefonu podany jako do kontaktu w razie zagubienia.
              </p>

              <Input
                placeholder="Numer telefonu, który ubezpieczasz"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    origin_mobile: e.target.value,
                  })
                }
                value={formData.origin_mobile}
              />
            </Space>
            <Space direction="vertical">
              <h3 style={{ marginBottom: 0 }}>
                <BellOutlined /> Powiadomienia
              </h3>
              <p style={{ marginTop: 0 }}>
                Powiadomienia będą wysyłane na e-mail i numer telefonu 
                podane jako do kontaktu w razie zagubienia.
              </p>
            </Space>
            <Checkbox
              checked={formData.is_email_notification_enabled}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  is_email_notification_enabled: e.target.checked,
                })
              }
            >
              Wyślij powiadomienie e-mail jeśli urządzenie znalezione
            </Checkbox>

            {planInfo && planInfo.plan.is_default === true ? (
              <>
                <Alert message="Plan darmowy nie obejmuje powiadomień SMS o odnalezionym urządzeniu. Aby skorzystać z tej funkcji, rozważ aktualizację do płatnego planu."></Alert>
              </>
            ) : (
              <></>
            )}

            <Checkbox
              checked={formData.is_sms_notification_enabled}
              disabled={planInfo && planInfo.plan.is_default}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  is_sms_notification_enabled: e.target.checked,
                })
              }
            >
              Wyślij powiadomienie SMS jeśli urządzenie znalezione
            </Checkbox>
          </Space>
        </Spin>
      </Modal>
    </>
  );
};

export default EditDeviceForm;
