import React, { useState, useEffect } from "react";
import LoginPage from "../../../Login/LoginPage";
import {
  Space,
  Row,
  Col,
  Breadcrumb,
} from "antd";
import { isLogged } from "../../../../helpers/account";
import ResourcesUsage from "../Data/ResourcesUsage";
import { UserOutlined, HomeOutlined } from "@ant-design/icons";
import CurrentPlanInfo from "./CurrentPlanInfo";

function AccountPlanPage() {
  if (isLogged()) {
    return (
      <>
        <Row
          style={{
            marginTop: "30px",
            marginBottom: "30px"
          }}
        >
          <Col
            span={24}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Space direction="vertical" size={30}>
              <Breadcrumb
                items={[
                  {
                    href: "/devices",
                    title: <HomeOutlined />,
                  },
                  {
                    title: "Bieżący plan",
                  },
                ]}
              />
              <ResourcesUsage></ResourcesUsage>
              <CurrentPlanInfo></CurrentPlanInfo>
            </Space>
          </Col>
        </Row>
      </>
    );
  } else {
    return <LoginPage></LoginPage>;
  }
}

export default AccountPlanPage;
