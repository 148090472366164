import React, { useState } from "react";
import { Space, Row, Col, Breadcrumb } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { isLogged } from "../../../../helpers/account";
import LoginPage from "../../../Login/LoginPage";
import StripeCheckoutPopup from "./StripeCheckoutPopup"; // Import the new component
import PlanCard from "./PlanCard"; // Import PlanCard component
import { plans } from "../../../../data/plans";


const PlanPickerPage = () => {
  const [popupVisible, setPopupVisible] = useState(false);
  const [planUuid, setPlanUuid] = useState(null);

  const handleSelectPlan = (uuid) => {
    setPlanUuid(uuid);
    setPopupVisible(true);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
    setPlanUuid(null);
  };

  if (isLogged()) {
    return (
      <>
        <Row style={{ marginTop: "30px", marginBottom: "30px" }}>
          <Col
            span={24}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Space direction="vertical" size={30} style={{maxWidth: "350px"}}>
              <Breadcrumb
                items={[
                  { href: "/devices", title: <HomeOutlined /> },
                  { href: "/account/plan", title: "Bieżący plan" },
                  { title: "Wybór planu cenowego" },
                ]}
              />
              <Space direction="vertical" size={30}>
                {plans.map((plan) => (
                  <PlanCard
                    key={plan.uuid}
                    plan={plan}
                    handleSelectPlan={handleSelectPlan}
                    showCta={true}
                  />
                ))}
              </Space>
            </Space>
          </Col>
        </Row>
        <StripeCheckoutPopup
          visible={popupVisible}
          onClose={handleClosePopup}
          planUuid={planUuid}
        />
      </>
    );
  } else {
    return <LoginPage />;
  }
};

export default PlanPickerPage;