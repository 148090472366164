import React, { useEffect, useState } from "react";
import { Modal, List, Button, message, Progress, Space } from "antd";
import { getAuthToken } from "../../../helpers/account";
import { api } from "../../../config/api";
import MapFactory from "./Map";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { fromLonLat } from "ol/proj";
import {
  Style,
  Icon,
  Text,
  Fill,
  Stroke,
  Circle as CircleStyle,
} from "ol/style";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import Cluster from "ol/source/Cluster";
import { ThunderboltOutlined } from "@ant-design/icons";
import { formatPolishDate } from "../../../helpers/date";

const LocationAllPopup = ({ visible, onClose }) => {
  const [map, setMap] = useState(null);
  const [features, setFeatures] = useState([]);
  const [locations, setLocations] = useState([]);
  const [progress, setProgress] = useState(0);
  const [clusterLayer, setClusterLayer] = useState(null);

  // Fetch all user devices and their locations
  const getAllLocations = async () => {
    try {
      // Step 1: Fetch all devices
      const response = await fetch(`${api.host}/devices`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `${getAuthToken()}`,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch devices");
      }

      const devices = await response.json();

      // Step 2: Fetch the locations for each device
      const locationPromises = devices.map((device) =>
        fetch(`${api.host}/devices/location/${device.uuid}`, {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `${getAuthToken()}`,
          }),
        })
          .then((res) => res.json())
          .then((locationData) => ({
            locations: locationData, // Assign the location data to the "locations" key
            nickname: device.name, // Attach the nickname or other device data
            uuid: device.uuid, // Attach uuid to help with identification
          }))
      );

      const allLocations = await Promise.all(locationPromises);
      // Step 3: Set all locations in state
      setLocations(allLocations);
    } catch (error) {
      message.error("Failed to fetch device locations.");
    }
  };

  // Random color generator for user clusters
  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  // Initialize the map when the modal becomes visible
  useEffect(() => {
    if (visible && !map) {
      const mapInstance = new MapFactory();
      setMap(mapInstance);

      const mapElement = document.getElementById("map");
      if (mapElement) {
        mapElement.innerHTML = "";
        mapInstance.initialize([19.1203094, 50.8118195]);
        mapInstance.map.getView().setZoom(6);
      }
    }
  }, [visible, map]);

  // Update the map with new locations whenever they change
  useEffect(() => {
    if (map && visible && locations.length > 0) {
      // Clear previous cluster layer if it exists
      if (clusterLayer) {
        map.map.removeLayer(clusterLayer);
      }

      const newFeatures = locations.flatMap((device) => {
        return device.locations.map((location) => {
          return new Feature({
            geometry: new Point(
              fromLonLat([location.longitude, location.latitude])
            ),
            nickname: device.nickname,
            id: device.uuid,
          });
        });
      });

      const clusterSource = new Cluster({
        distance: 40,
        source: new VectorSource({
          features: newFeatures,
        }),
      });

      const newClusterLayer = new VectorLayer({
        source: clusterSource,
        style: function (feature) {
          const size = feature.get("features").length;
          let style;

          if (size > 1) {
            // Get a random color for each cluster
            const clusterColor = getRandomColor();
            style = new Style({
              image: new CircleStyle({
                radius: 15,
                fill: new Fill({
                  color: clusterColor,
                }),
                stroke: new Stroke({
                  color: "#fff",
                  width: 2,
                }),
              }),
              text: new Text({
                text: size.toString(),
                fill: new Fill({
                  color: "#fff",
                }),
              }),
            });
          } else {
            const singleFeature = feature.get("features")[0];
            style = new Style({
              image: new Icon({
                anchor: [0.5, 1],
                src: "/img/map-pin.png",
              }),
              text: new Text({
                font: "12px sans-serif",
                text: singleFeature.get("nickname"), // Display the user's nickname
                offsetY: -25,
                fill: new Fill({ color: "#000" }),
                stroke: new Stroke({ color: "#fff", width: 2 }),
              }),
            });
          }
          return style;
        },
      });

      map.map.addLayer(newClusterLayer);
      setClusterLayer(newClusterLayer); // Save new cluster layer in state
      setFeatures(newFeatures);
    }
  }, [map, locations, visible]);

  useEffect(() => {
    if (visible) {
      getAllLocations();
    }
  }, [visible]);

  return (
    <Modal
      title="Wszyscy użytkownicy na mapie"
      open={visible}
      onCancel={onClose}
      footer={null}
      width={800}
    >
      <div
        id="map"
        className="ol-map"
        style={{ height: 500, marginBottom: 20 }}
      ></div>
      <Button onClick={() => getAllLocations()} style={{ marginBottom: 20 }}>
        Odśwież mapę
      </Button>
      <Progress
        percent={progress}
        status="active"
        showInfo={false}
        style={{ marginBottom: 20 }}
      />
      <div style={{ height: 200, marginBottom: 20, overflowY: "scroll" }}>
        {console.log(locations)}
        <List
          itemLayout="horizontal"
          dataSource={
            locations
              .flatMap((device) =>
                device.locations.map((loc) => ({
                  nickname: device.nickname,
                  latitude: loc.latitude,
                  longitude: loc.longitude,
                  batteryLevel: loc.batteryLevel,
                  timestamp: loc.timestamp, // Include timestamp
                }))
              )
              .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)) // Sort by timestamp
          }
          renderItem={(item) => (
            <List.Item style={{ cursor: "pointer" }}>
              <List.Item.Meta
                title={item.nickname || "Unknown Device"} // Handle missing nickname
                description={
                  <>
                    <Space>
                      {formatPolishDate(item.timestamp)} Położenie:{" "}
                      {item.latitude}, {item.longitude}{" "}
                    </Space>
                    <ThunderboltOutlined /> Bateria: {item.batteryLevel}%
                  </>
                }
              />
            </List.Item>
          )}
        />
      </div>
    </Modal>
  );
};

export default LocationAllPopup;
