import React from "react";
import { Card, Avatar, Space, Row, Col } from "antd";

const RegulationsPage = () => {
  return (
    <Row
      style={{
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <Col md={12} sm={20}>
        <h1 className="headline offer">
          Regulamin Usługi CELL ID
        </h1>
        <Space direction="vertical">
          <h2>Rozdział 1: Postanowienia Ogólne</h2>

          <h3>§ 1. Definicje</h3>
          <p>
            1. <strong>Usługa CELL ID</strong>: Usługa wirtualnej wizytówki urządzenia oferowana przez Cell ID, prowadzoną przez osobę prywatną - Andrzej Bernat, z siedzibą w Choszcznie, ul. Wolności 64B, 73-200 Choszczno, będącym bezpośrednim dostawcą usług wizytówek urządzeń.
          </p>
          <p>
            2. <strong>Użytkownik</strong>: Osoba korzystająca z usługi CELL ID w celu utworzenia wirtualnej wizytówki swojego urządzenia.
          </p>
          <p>
            3. <strong>Urządzenie</strong>: Telefon komórkowy, tablet lub inne urządzenie elektroniczne, dla którego Użytkownik utworzył wirtualną wizytówkę za pomocą Usługi CELL ID.
          </p>
          <p>
            4. <strong>Wirtualna Wizytówka</strong>: Informacje kontaktowe Użytkownika dostępne po zeskanowaniu wygenerowanego kodu QR.
          </p>
          <p>
            5. <strong>Platforma</strong>: Oprogramowanie i systemy używane do świadczenia Usługi CELL ID.
          </p>

          <h3>§ 2. Warunki Ogólne</h3>
          <p>
            1. Usługa CELL ID dostępna jest wyłącznie dla pełnoletnich osób.
          </p>
          <p>
            2. Cell ID dokłada wszelkich starań, aby oprogramowanie platformy było niezawodne, ale nie odpowiada za opóźnienia wynikające z potencjalnych awarii platformy.
          </p>
          <p>
            3. W przypadku opóźnień spowodowanych awarią platformy, Użytkownicy muszą komunikować się bezpośrednio w celu rozwiązania ewentualnych problemów.
          </p>
          <p>
            4. Usługa CELL ID dostępna jest na terytorium Polski, z wyjątkiem innych jurysdykcji, w których dostępność usługi może być ograniczona przez obowiązujące przepisy.
          </p>
          <p>
            5. Opłaty za Usługę CELL ID zostaną pobrane zarówno w przypadku utworzenia wirtualnej wizytówki, jak i jej późniejszego utrzymania.
          </p>

          <h2>Rozdział 2: Zakładanie Konta</h2>

          <h3>§ 3. Rejestracja Konta</h3>
          <p>
            1. Aby skorzystać z Usługi CELL ID, Użytkownik musi zarejestrować konto na stronie Cell ID.
          </p>
          <p>
            2. Podczas rejestracji Użytkownik zobowiązuje się do podania prawdziwych i aktualnych informacji.
          </p>

          <h3>§ 4. Dokumenty Weryfikacyjne</h3>
          <p>
            1. Cell ID może zażądać od Użytkownika dostarczenia odpowiednich dokumentów w celu weryfikacji jego tożsamości.
          </p>
          <p>
            2. W przypadku braku dostarczenia wymaganych dokumentów, Cell ID zastrzega sobie prawo do zawieszenia lub zablokowania konta Użytkownika.
          </p>

          <h2>Rozdział 3: Korzystanie z Usługi</h2>

          <h3>§ 5. Tworzenie Wirtualnej Wizytówki</h3>
          <p>
            1. Użytkownik może utworzyć wirtualną wizytówkę swojego urządzenia za pomocą platformy CELL ID.
          </p>
          <p>
            2. Po utworzeniu wirtualnej wizytówki, Użytkownik otrzymuje unikalny kod QR, który można umieścić na urządzeniu.
          </p>

          <h3>§ 6. Opłaty i Prowizje</h3>
          <p>
            1. Cell ID może pobierać opłaty i prowizje za korzystanie z Usługi CELL ID. Informacje na ten temat są dostępne na stronie internetowej Cell ID.
          </p>
          <p>
            2. Cell ID zastrzega sobie prawo do zmiany opłat i prowizji w dowolnym czasie, z zachowaniem wymaganego okresu powiadomienia.
          </p>

          <h2>Rozdział 4: Bezpieczeństwo i Poufność</h2>

          <h3>§ 7. Bezpieczeństwo Danych</h3>
          <p>
            1. Użytkownik ma obowiązek zabezpieczenia swojego konta i hasła dostępu.
          </p>
          <p>
            2. Cell ID nie ponosi odpowiedzialności za nieautoryzowany dostęp do konta Użytkownika spowodowany jego niedbalstwem.
          </p>

          <h3>§ 8. Poufność Informacji</h3>
          <p>
            1. Cell ID zobowiązuje się do zachowania poufności wszelkich danych Użytkownika, z wyjątkiem przypadków przewidzianych przez prawo.
          </p>

          <h2>Rozdział 5: Zakończenie Usługi</h2>

          <h3>§ 9. Zamknięcie Konta</h3>
          <p>1. Użytkownik ma prawo zamknąć swoje konto w dowolnym czasie.</p>
          <p>
            2. Cell ID zastrzega sobie prawo do zamknięcia konta Użytkownika w przypadku naruszenia regulaminu lub podejrzenia oszustwa.
          </p>

          <h2>Rozdział 6: Postanowienia Końcowe</h2>

          <h3>§ 10. Postanowienia Ogólne</h3>
          <p>
            1. Regulamin obowiązuje od 20 października 2023 i jest dostępny na stronie Cell ID.
          </p>
          <p>
            2. Wszelkie spory wynikłe z korzystania z Usługi CELL ID będą rozpatrywane przez sąd właściwy na terytorium Polski.
          </p>
          <p>
            3. Wszelkie zmiany w regulaminie będą ogłaszane na stronie Cell ID.
          </p>

          <p>
            Zapoznaj się z tym regulaminem dokładnie przed korzystaniem z Usługi CELL ID. Jeśli masz pytania lub potrzebujesz dalszej pomocy, zawsze możesz skontaktować się z nami.
          </p>
        </Space>
      </Col>
    </Row>
  );
};

export default RegulationsPage;
