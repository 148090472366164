import { Helmet } from "react-helmet-async";
import PlanCard from "../Dashboard/Account/Plan/PlanCard";
import { Space, Button, Divider, Col, Row, Typography } from "antd";
import { plans } from "../../data/plans";
import OfferTestPopup from "./OfferTestPopup";
const { Title } = Typography;

const PricingPage = () => {
  const logGAEvent = (eventName, eventParams) => {
    if (window.gtag) {
      window.gtag("event", eventName, eventParams);
    }
  };

  const handleCalendlyClick = (event) => {
    event.preventDefault();
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url: "https://calendly.com/smieciappka?hide_gdpr_banner=1",
      });
    } else {
      console.error("Calendly is not loaded.");
    }
  };
  const handleAddDevice = () => {
    const deviceData = {
      name: "Mój telefon",
      type: 1,
    };

    localStorage.setItem("deviceData", JSON.stringify(deviceData));

    logGAEvent("click", {
      event_category: "engagement",
      event_label:
        "Plan Darmowy z 1 urządzeniem na 12 miesięcy. Jeśli będziesz potrzebować więcej - dokup.",
      value: 1,
    });

    window.location.href = "/account/register";
  };

  return (
    <>
      {" "}
      <Helmet>
        <title>Pakiety Cell ID</title>
      </Helmet>
      <Row justify="space-between" align="middle" gutter={12}>
        <Col md={4}></Col>
        <Col md={8} style={{ padding: "0px 30px" }}>
          <Space direction="vertical">
            <Title>
            Aplikacja CELLID sprawia, że utrata telefonu jest mniej stresująca i zwiększa szanse na jego odzyskanie.
            </Title>

          </Space>
        </Col>
        <Col md={8}>
        <img src="/img/calm.png" style={{ height: "400px" }} />
        </Col>
        <Col md={4}></Col>
      </Row>
      <div className="hero-background-1">
        <Row justify="space-between" align="top" gutter={12}>
          <Col md={4}></Col>
          <Col md={16} style={{ padding: "0px 30px", textAlign: "center" }}>
            <Space>
              <img src="/img/teachers.png" style={{ width: "100px" }} />
              <p>Zatwierdzona przez nauczycieli</p>
              <a
                href="https://play.google.com/store/apps/details?id=pl.cellid"
                target="_blank"
                rel="noreferrer"
                title="Link do pobrania CELLID w Google Play"
              >
                <img
                  src="/img/google-play-badge.png"
                  style={{ width: "100px" }}
                  alt="CELLID w Google Play"
                />
              </a>
            </Space>
          </Col>

          <Col md={4}></Col>
        </Row>
      </div>
     <br/>
      <div style={{ maxWidth: "350px", margin: "auto" }}>
        <Space direction="vertical" size={30}>
          {plans.map((plan) => (
            <PlanCard key={plan.uuid} plan={plan} showCta={false} />
          ))}
        </Space>
      </div>
      <div className="ceo">
        <p>
          {" "}
          Chcę minimalizować ryzyko utraty Twoich urządzeń,
          umożliwiając szybkie i łatwe odzyskanie, łącząc znalazców z
          właścicielami urządzeń. Wybierz CELL ID i ciesz się spokojem, wiedząc,
          że Twoje urządzenia są bezpieczne.
        </p>
        <div className="photo">
          <img src="/img/3.svg" alt="CEO" />
        </div>
        <div className="sign">
          <img src="/img/4.svg" alt="Signature" />
        </div>
        <div className="postpay">Twórca Rejestru CELL ID</div>
      </div>
      <div className="partners">
        <img src="/img/14.svg" />
      </div>
      <OfferTestPopup />
    </>
  );
};

export default PricingPage;
