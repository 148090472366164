import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import { fromLonLat } from "ol/proj";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import Icon from "ol/style/Icon";
import Text from "ol/style/Text";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import Feature from "ol/Feature";
import { Circle as CircleGeom, Point } from "ol/geom";
import FullScreen from "ol/control/FullScreen";
import Zoom from "ol/control/Zoom";
import ScaleLine from "ol/control/ScaleLine";

export default class MapFactory {
  static instance = null;

  constructor() {
    if (MapFactory.instance) {
      return MapFactory.instance;
    }
    this.placesLayer = null;
    this.donatedPlacesLayer = null;
    this.reusedPlacesLayer = null;
    this.userPositionLayer = null;
    this.accuracyFeature = null;
    this.positionFeature = null;
    this.map = null;
    MapFactory.instance = this;
  }

  initialize(coordinates) {
    let map = new Map({
      view: new View({
        center: fromLonLat(coordinates),
        zoom: 15,
      }),
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        this.composePlacesLayer(),
        this.composeUserPositionLayer(coordinates),  // Add user position layer
      ],
      target: "map",
      loadTilesWhileAnimating: true,
      controls: [
        new Zoom(),
        new FullScreen(),
        new ScaleLine(),
      ],
    });

    map.on("click", (evt) => {
      const feature = map.forEachFeatureAtPixel(evt.pixel, function (feature) {
        return feature;
      });
      if (feature) {
        // Handle feature click event
      }
    });

    this.map = map;
    return this;
  }

  composeAccuracyFeature() {
    if (this.accuracyFeature != null) {
      return this.accuracyFeature;
    }
    return (this.accuracyFeature = new Feature());
  }

  composePlacesLayer() {
    if (this.placesLayer != null) {
      return this.placesLayer;
    }
    return (this.placesLayer = new VectorLayer({
      source: new VectorSource(),
      style: function (feature) {
        let iconStyle = new Style({
          image: new Icon({
            anchor: [0.1, 1.2],
            anchorXUnits: "fraction",
            anchorYUnits: "fraction",
            src: "/img/map-pin.png",
          }),
        });

        var labelStyle = new Style({
          text: new Text({
            font: "14px Roboto",
            overflow: true,
            fill: new Fill({
              color: "#000",
            }),
            backgroundFill: new Fill({
              color: "#fff",
            }),
            padding: [4, 4, 4, 4],
            offsetY: -89,
            offsetX: 69,
            text: feature.get("label"),
            backgroundStroke: new Stroke({
              color: "#000",
              width: 2,
              lineCap: "round",
              lineJoin: "round",
            }),
          }),
        });

        return [iconStyle, labelStyle];
      },
      updateWhileAnimating: true,
      updateWhileInteracting: true,
    }));
  }

  composePositionFeature(coordinates) {
    let positionFeature = new Feature({
      geometry: new Point(fromLonLat(coordinates)),
    });

    positionFeature.setStyle(
      new Style({
        image: new CircleStyle({
          radius: 6,  // Set a larger radius for better visibility
          fill: new Fill({
            color: "#3399CC",
          }),
          stroke: new Stroke({
            color: "#fff",
            width: 2,
          }),
        }),
      })
    );
    return positionFeature;
  }

  composeCircleFeature(coordinates) {
    let circleFeature = new Feature({
      geometry: new CircleGeom(fromLonLat(coordinates), 100),  // Set radius in meters
    });

    circleFeature.setStyle(
      new Style({
        stroke: new Stroke({
          color: "rgba(51, 153, 204, 0.6)",  // Semi-transparent blue
          width: 2,
        }),
        fill: new Fill({
          color: "rgba(51, 153, 204, 0.2)",  // More transparent fill
        }),
      })
    );
    return circleFeature;
  }

  composeUserPositionLayer(coordinates) {
    if (this.userPositionLayer != null) {
      return this.userPositionLayer;
    }

    return (this.userPositionLayer = new VectorLayer({
      source: new VectorSource({
        features: [
          this.composePositionFeature(coordinates),
          this.composeCircleFeature(coordinates),
        ],
      }),
    }));
  }
}
