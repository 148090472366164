import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Card, message, Spin } from "antd";
import { Helmet } from "react-helmet-async";
import { api } from "../../../config/api";
import { MailOutlined, MobileOutlined } from "@ant-design/icons";
import ConfettiExplosion from "react-confetti-explosion";

const CardPage = () => {
  const { uuid } = useParams();
  const [loading, setLoading] = useState(false);
  const [deviceData, setDeviceData] = useState(null);
  const [language, setLanguage] = useState("pl"); // Default language is Polish
  const [isExploding, setIsExploding] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const fetchDeviceData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${api.host}/devices/public/${uuid}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      setDeviceData({
        name: data.name,
        message: data.message,
        type: data.device_type_id,
        mobile: data.mobile,
        email: data.email,
      });
      setIsExploding(true);
      if (data.is_sms_notification_enabled) {
        messageApi.open({ type: "success", content: "Wysłaliśmy SMS do właściciela, być może zaraz oddzwoni." });
      }
      if (data.is_email_notification_enabled) {
        messageApi.open({ type: "success", content: "Daliśmy znać właścicielowi wysyłając e-mail." });
      }
    } catch (error) {
      console.error("Error fetching device data:", error);
      message.error("Failed to fetch device data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (uuid) {
      fetchDeviceData();
    }
  }, [uuid]);

  const toggleLanguage = () => {
    setLanguage((prevLanguage) => (prevLanguage === "pl" ? "en" : "pl"));
  };

  return (
    <>
      {contextHolder}
      {isExploding && <ConfettiExplosion />}
      <Helmet>
        <title>
          CellID -{" "}
          {language === "pl"
            ? "Łączymy urządzenia mobilne z kontaktem do właściciela"
            : "Connecting lost devices with their owners"}
        </title>
      </Helmet>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div style={{ maxWidth: "350px", textAlign: "center" }}>
          <button onClick={toggleLanguage} style={{ marginBottom: "20px" }}>
            {language === "pl" ? "Switch to English" : "Przełącz na Polski"}
          </button>
          <Card>
            <Spin spinning={loading}>
              {deviceData ? (
                <div>
                  <a href="/" target="_blank">
                    <img src="/img/cellid_logo.svg" alt="CellID Logo" />
                  </a>
                  <h2>
                    {language === "pl"
                      ? "Odnalazłeś zagubione urządzenie, gratulacje! W imieniu właściciela prosimy o zwrot. Poniżej znajdziesz informacje jak to zrobić."
                      : "You have found a lost device. On behalf of the owner, we kindly ask for its return. Below are the contact details."}
                  </h2>
                  <h2>
                    {language === "pl"
                      ? "Wiadomość od właściela:"
                      : "Message from the owner:"}
                  </h2>
                  <p>{deviceData.message}</p>
                  <h2>
                    {language === "pl"
                      ? "Jak się skontaktować z właścicielem?"
                      : "How to contact the owner?"}
                  </h2>
                  <p>
                    <MobileOutlined />{" "}
                    {language === "pl" ? "Telefon:" : "Phone:"}{" "}
                    <a href={`tel:${deviceData.mobile}`}>{deviceData.mobile}</a>
                  </p>
                  <p>
                    <MailOutlined /> {language === "pl" ? "E-mail:" : "Email:"}{" "}
                    {deviceData.email}
                  </p>
                </div>
              ) : (
                <p>
                  {language === "pl"
                    ? "Szukamy właściciela..."
                    : "Searching for the owner..."}
                </p>
              )}
            </Spin>
          </Card>
        </div>
      </div>
    </>
  );
};

export default CardPage;
