import React, { useState, useEffect } from "react";
import {
  Space,
  Button,
  Modal,
  Input,
  Steps,
  message,
  Spin,
  Select,
  Alert,
  Checkbox,
} from "antd";
import { isLogged, getAuthToken } from "../../../helpers/account";
import { api } from "../../../config/api";
import { useDispatch } from "react-redux";
import ResourcesUsage from "../../Dashboard/Account/Data/ResourcesUsage";
import {
  MailOutlined,
  MobileOutlined,
  BellOutlined,
  AimOutlined,
} from "@ant-design/icons";

const { TextArea } = Input;
const { Option } = Select;

const NewDeviceForm = ({ prefilledData }) => {
  const [newDeviceModalOpen, setNewDeviceModalOpen] = useState(false);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [planInfo, setPlanInfo] = useState(null);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    name: "",
    message:
      "Cześć! Prawdopodobnie znalazłeś/aś mój telefon. To super wiadomość! Napisz do mnie sms albo napisz na adres e-mail, podeślę czek Blik na drobny upominek oraz podam adres do wysyłki.",
    type: "",
    mobile: "",
    origin_mobile: "",
    email: "",
    is_email_notification_enabled: false,
    is_sms_notification_enabled: false,
  });

  const types = [
    { value: 1, label: "Telefon" },
    { value: 2, label: "Tablet" },
    { value: 3, label: "Laptop" },
    { value: 4, label: "Inne" },
  ];

  useEffect(() => {
    const fetchCurrentPlan = async () => {
      try {
        const apiUrl = `${api.host}/accounts/plans/current`;
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `${getAuthToken()}`,
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to fetch current plan");
        }

        const data = await response.json();
        setPlanInfo(data.currentPlan);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching current plan:", error);
      }
    };

    fetchCurrentPlan();
  }, [newDeviceModalOpen]);

  const parseResourceString = (resourceString) => {
    const match = resourceString.match(/\("([^"]+)",(\d+),(\d+),(-?\d+)\)/);
    if (match) {
      return {
        resource: match[1],
        total: parseInt(match[2], 10),
        used: parseInt(match[3], 10),
        remaining: parseInt(match[4], 10),
      };
    }
    return null;
  };

  const handleCreateDevice = async () => {
    try {
      localStorage.removeItem("deviceData");

      const devicesResponse = await fetch(`${api.host}/devices`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${getAuthToken()}`,
        },
      });

      if (!devicesResponse.ok) throw new Error("Failed to fetch devices count");

      const devices = await devicesResponse.json();
      const devicesCount = devices.length;

      const resourcesResponse = await fetch(
        `${api.host}/accounts/plans/resources`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${getAuthToken()}`,
          },
        }
      );

      if (!resourcesResponse.ok) throw new Error("Failed to fetch resources");

      const resourcesData = await resourcesResponse.json();
      const parsedResources = resourcesData.resources.map((item) =>
        parseResourceString(item.check_resources_left)
      );

      if (parsedResources[0].total > devicesCount) {
        proceedToCreateDevice();
      } else {
        setUpgradeModalOpen(true);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const proceedToCreateDevice = async () => {
    if (validateFormData()) {
      setLoading(true);
      const apiData = {
        ...formData,
        mobile: formData.mobile.replace(/\D/g, ""), // Remove all non-digit characters from mobile
        origin_mobile: formData.origin_mobile.replace(/\D/g, ""), // Remove all non-digit characters from mobile
      };

      const apiUrl = `${api.host}/devices`;

      if (isLogged()) {
        try {
          const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${getAuthToken()}`,
            },
            body: JSON.stringify(apiData),
          });

          if (!response.ok) throw new Error("Failed to create device");

          const data = await response.json();

          message.success("Urządzenie zostało utworzone.");
          dispatch({
            type: "transactionCreated",
            payload: {
              transactionCreated: data.uuid,
            },
          });
          setNewDeviceModalOpen(false);
        } catch (error) {
          console.error("Error creating device:", error);
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const validateFormData = () => {
    if (!formData.name || !formData.message || !formData.type) {
      message.error("Wszystkie pola są wymagane.");
      return false;
    }
    return true;
  };

  const handleNewDevice = () => {
    setNewDeviceModalOpen(true);
  };

  const handleCancelNewDevice = () => {
    setNewDeviceModalOpen(false);
    localStorage.removeItem("deviceData");
  };

  const handleCancelUpgradeModal = () => {
    setUpgradeModalOpen(false);
  };

  const handlePlanPicker = () => {
    window.location.href = "/account/plan/picker"; // Redirect to plan picker page
  };

  const handleNewDeviceFormClose = () => {
    setNewDeviceModalOpen(false);
    localStorage.removeItem("deviceData");
  };

  useEffect(() => {
    if (prefilledData) {
      setNewDeviceModalOpen(true);
      setFormData({
        name: prefilledData.name,
        type: prefilledData.type,
        message:
          "Cześć! Prawdopodobnie znalazłeś/aś mój telefon. To super wiadomość! Napisz do mnie sms na numer TUTAJ_NUMER_TELEFONU albo napisz na adres e-mail TUTAJ_ADRES_EMAIL, podeślę czek Blik na drobny upominek oraz podam adres do wysyłki.",
      });
    }
  }, [prefilledData]);

  return (
    <>
      <Button className="addNewDeviceButton" onClick={handleNewDevice} type="primary" size="small">
        Dodaj telefon
      </Button>

      <Modal
        title="Nowe urządzenie"
        visible={newDeviceModalOpen}
        onOk={handleCreateDevice}
        onCancel={handleCancelNewDevice}
        onClose={handleNewDeviceFormClose}
        okText="Dodaj"
        cancelText="Anuluj"
      >
        <Spin spinning={loading}>
          <Space size={15} direction="vertical" style={{ width: "100%" }}>
            <Steps
              size="small"
              current={0}
              items={[
                { title: "Utworzenie urządzenia" },
                { title: "Pobranie kodu QR" },
              ]}
            />

            <Input
              placeholder="Nazwa urządzenia"
              onChange={(e) =>
                setFormData({
                  ...formData,
                  name: e.target.value,
                })
              }
              value={formData.name}
            />
            <Input
              prefix={<MobileOutlined />}
              placeholder="Numer na jaki zadzwonić w razie odnalezienia tego urządzenia"
              onChange={(e) =>
                setFormData({
                  ...formData,
                  mobile: e.target.value,
                })
              }
              value={formData.mobile}
            />
            <Input
              prefix={<MailOutlined />}
              placeholder="Adres e-mail na jakie napisać w razie odnalezienia tego urządzenia"
              onChange={(e) =>
                setFormData({
                  ...formData,
                  email: e.target.value,
                })
              }
              value={formData.email}
            />
            <TextArea
              placeholder="Wiadomość dla znalazcy"
              rows={4}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  message: e.target.value,
                })
              }
              value={formData.message}
            />
            <Select
              placeholder="Wybierz typ urządzenia"
              onChange={(value) =>
                setFormData({
                  ...formData,
                  type: value,
                })
              }
              value={formData.type}
              style={{ width: "100%" }}
            >
              {types.map((device) => (
                <Option key={device.value} value={device.value}>
                  {device.label}
                </Option>
              ))}
            </Select>
            <Space direction="vertical">
              <h3 style={{ marginBottom: 0 }}>
                <AimOutlined /> Funkcja "Gdzie jesteś"
              </h3>
              <p style={{ marginTop: 0 }}>
                Funkcja pozwala na otrzymanie wiadomości SMS z zagubionego
                telefonu z jego lokalizacją. Jest to przydatne w przypadku braku
                Internetu w zagubionym telefonie. Wiadomość zostanie wysyłane na
                numer telefonu podany jako do kontaktu w razie zagubienia.
              </p>

              <Input
                placeholder="Numer telefonu, który ubezpieczasz"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    origin_mobile: e.target.value,
                  })
                }
                value={formData.origin_mobile}
              />
            </Space>
            <Space direction="vertical">
              <h3 style={{ marginBottom: 0 }}>
                <BellOutlined /> Powiadomienia
              </h3>
              <p style={{ marginTop: 0 }}>
                Powiadomienia będą wysyłane na e-mail i numer telefonu podane
                jako do kontaktu w razie zagubienia.
              </p>
            </Space>
            <Checkbox
              checked={formData.is_email_notification_enabled}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  is_email_notification_enabled: e.target.checked,
                })
              }
            >
              Wyślij powiadomienie e-mail jeśli urządzenie znalezione
            </Checkbox>

            {planInfo && planInfo.plan.is_default === true ? (
              <>
                <Alert message="Plan darmowy nie obejmuje powiadomień SMS o odnalezionym urządzeniu. Aby skorzystać z tej funkcji, rozważ aktualizację do płatnego planu."></Alert>
              </>
            ) : (
              <></>
            )}

            <Checkbox
              checked={formData.is_sms_notification_enabled}
              disabled={planInfo && planInfo.plan.is_default}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  is_sms_notification_enabled: e.target.checked,
                })
              }
            >
              Wyślij powiadomienie SMS jeśli urządzenie znalezione
            </Checkbox>
          </Space>
        </Spin>
      </Modal>

      <Modal
        title="Zaktualizuj plan"
        visible={upgradeModalOpen}
        onCancel={handleCancelUpgradeModal}
        footer={[
          <Button key="cancel" onClick={handleCancelUpgradeModal}>
            Anuluj
          </Button>,
          <Button key="goPlanPicker" type="primary" onClick={handlePlanPicker}>
            Zwiększ ilość urządzeń
          </Button>,
        ]}
      >
        <Space direction="vertical" size={30}>
          <Alert
            message="Osiągnąłeś limit urządzeń. Zaktualizuj swój plan, aby dodać więcej urządzeń."
            type="warning"
          />
          <ResourcesUsage />
        </Space>
      </Modal>
    </>
  );
};

export default NewDeviceForm;
