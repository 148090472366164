import React, { useState, useEffect } from "react";
import { Card, Input, Space, Button, Row, Col, message, Spin, Checkbox } from "antd";
import { UserOutlined, BlockOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { api } from "../../config/api";
import { Link } from "react-router-dom";
import { destroyAuthToken } from "../../helpers/account";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [emailConfirmed, setEmailConfirmed] = useState(true);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [autoLogin, setAutoLogin] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    const savedAutoLogin = localStorage.getItem("autoLogin");
    if (savedAutoLogin) {
      const savedEmail = localStorage.getItem("email");
      const savedAuthToken = localStorage.getItem("authToken");
      if (savedEmail && savedAuthToken) {
        setEmail(savedEmail);
        sessionStorage.setItem("authToken", savedAuthToken);
        window.location.href = "/devices";
      }
    }
  }, []);

  const handleLogin = () => {
    const apiUrl = `${api.host}/accounts/login`;
    setLoading(true);
    const requestBody = { email, password, code };

    fetch(apiUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setLoading(false);
            if (data.authToken) {
              messageApi.open({ type: "success", content: "Dane poprawne, jesteś zalogowany" });
              sessionStorage.setItem("authToken", data.authToken);
              sessionStorage.setItem("uuid", data.uuid);
              if (autoLogin) {
                localStorage.setItem("autoLogin", "true");
                localStorage.setItem("email", email);
                localStorage.setItem("authToken", data.authToken);
              }
              setTimeout(() => {
                window.location.href = "/devices";
              }, 2000);
            } else {
              setEmailConfirmed(false);
              messageApi.open({ type: "error", content: "Adres e-mail nie został potwierdzony" });
            }
          });
        } else {
          setLoading(false);
          messageApi.open({ type: "warning", content: "Błędne dane logowania" });
          destroyAuthToken();
        }
      })
      .catch(() => {
        setLoading(false);
        messageApi.open({ type: "warning", content: "Błędne dane logowania" });
        destroyAuthToken();
      });
  };

  return (
    <Row style={{ height: 500, justifyContent: "center", minHeight: "100vh" }}>
      {contextHolder}
      <Col style={{ display: "flex", alignItems: "center", justifyContent: "center", flex: 1 }}>
        <Spin spinning={loading}>
          <Card
            title={<><img src="/img/devices.png" /> Moje CELLID</>}
            extra={<Link to="/"><CloseCircleOutlined style={{ color: "#8c8c8c" }} /></Link>}
            style={{ width: 350, boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)" }}
          >
            <Space size={30} direction="vertical">
              <Input
                size="large"
                autoComplete="off"
                placeholder="Adres e-mail"
                prefix={<UserOutlined />}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Input.Password
                size="large"
                autoComplete="off"
                placeholder="Hasło"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {emailConfirmed === false && (
                <>
                  <p>Przepisz otrzymany kod weryfikacyjny, który wysłaliśmy na podany adres e-mail.</p>
                  <Input
                    size="large"
                    autoComplete="off"
                    placeholder="Kod weryfikacyjny"
                    prefix={<BlockOutlined />}
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </>
              )}
              <Checkbox
                checked={autoLogin}
                onChange={(e) => setAutoLogin(e.target.checked)}
              >
                Zaloguj automatycznie przy następnym logowaniu
              </Checkbox>
              <Button type="primary" onClick={handleLogin}>
                Zaloguj
              </Button>
              <p style={{ marginTop: 0 }}>
                <Link to="/account/password-reset">Zmień hasło</Link> jeśli zapomniałeś.{" "}
                <Link to="/account/register">Załóż darmowe konto</Link> jeśli jeszcze nie posiadasz.
              </p>
            </Space>
          </Card>
        </Spin>
      </Col>
    </Row>
  );
};

export default LoginPage;
