import React from "react";
import { Card, Avatar, Space, Row, Col } from "antd";

const PrivacyPage = () => {
  return (
    <Row
      style={{
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <Col md={12} sm={20}>
        <h1 className="headline offer">Polityka Prywatności</h1>
        <Space direction="vertical">
          <p>
            Niniejsza Polityka Prywatności opisuje zasady przetwarzania danych
            osobowych przez aplikację CELL ID, której właścicielem i
            administratorem jest Andrzej Bernat, z siedzibą w Choszcznie, ul.
            Wolności 64B, 73-200 Choszczno.
          </p>

          <h3>§ 1. Zakres Polityki Prywatności</h3>
          <p>
            Polityka ta dotyczy aplikacji CELL ID, dostępnej w Google Play oraz
            online jak i wszelkich danych osobowych i wrażliwych użytkowników
            aplikacji.
          </p>

          <h3>§ 2. Rodzaje gromadzonych danych</h3>
          <p>
            1. <strong>Dane lokalizacji</strong>: Aplikacja może uzyskiwać
            dostęp do danych lokalizacyjnych urządzenia użytkownika w celu
            świadczenia usług związanych z wirtualną wizytówką urządzenia. Dane
            te są przetwarzane wyłącznie w celu świadczenia usługi i nie są
            udostępniane osobom trzecim.
          </p>
          <p>
            2. <strong>Dane kontaktowe</strong>: Podczas rejestracji w aplikacji
            mogą być zbierane takie dane jak imię, nazwisko, adres e-mail oraz
            numer telefonu.
          </p>
          <p>
            3. <strong>Dane urządzenia</strong>: Informacje dotyczące urządzenia
            użytkownika, takie jak model urządzenia, wersja systemu
            operacyjnego, identyfikator urządzenia (ID), mogą być zbierane w
            celu prawidłowego działania aplikacji.
          </p>

          <h3>§ 3. Sposób wykorzystania danych</h3>
          <p>
            Zebrane dane osobowe mogą być wykorzystywane do następujących celów:
          </p>
          <ul>
            <li>
              Świadczenie usług oferowanych przez aplikację CELL ID, w tym
              tworzenie i zarządzanie wirtualnymi wizytówkami urządzeń.
            </li>
            <li>
              Udoskonalanie funkcji aplikacji oraz zapewnianie lepszego
              doświadczenia użytkownika.
            </li>
            <li>Zapewnienie zgodności z obowiązującymi przepisami prawa.</li>
          </ul>

          <h3>§ 4. Udostępnianie danych</h3>
          <p>
            Nie udostępniamy Twoich danych osobowych żadnym zewnętrznym
            podmiotom, chyba że jest to wymagane przez prawo, lub w sytuacjach,
            gdy jest to niezbędne do realizacji usługi zgodnie z niniejszą
            Polityką Prywatności.
          </p>

          <h3>§ 5. Bezpieczeństwo danych</h3>
          <p>
            Dokładamy wszelkich starań, aby zapewnić odpowiedni poziom
            bezpieczeństwa Twoich danych osobowych. Dane są chronione przed
            nieautoryzowanym dostępem, zmianą, ujawnieniem lub zniszczeniem.
          </p>

          <h3>§ 6. Retencja danych</h3>
          <p>
            Przechowujemy dane osobowe tylko tak długo, jak jest to konieczne do
            realizacji celów, dla których zostały zebrane, lub zgodnie z
            wymaganiami prawnymi.
          </p>

          <h3>§ 7. Prawa użytkownika</h3>
          <p>
            Masz prawo do dostępu do swoich danych osobowych, ich sprostowania,
            usunięcia lub ograniczenia przetwarzania. W celu skorzystania z tych
            praw prosimy o kontakt z nami.
          </p>

          <h3>§ 8. Kontakt</h3>
          <p>
            W razie jakichkolwiek pytań lub wątpliwości dotyczących niniejszej
            Polityki Prywatności, prosimy o kontakt pod adresem e-mail: **[Twój
            kontakt e-mail]**.
          </p>

          <h3>§ 9. Zmiany w Polityce Prywatności</h3>
          <p>
            Zastrzegamy sobie prawo do wprowadzania zmian w niniejszej Polityce
            Prywatności. O wszelkich zmianach będziemy informować na stronie
            internetowej lub w aplikacji.
          </p>

          <p>Niniejsza Polityka Prywatności obowiązuje od dnia 11/08/2024.</p>
        </Space>
      </Col>
    </Row>
  );
};

export default PrivacyPage;
