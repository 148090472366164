export const plans = [
  {
    uuid: "6750ea40-cf8d-4396-be09-6d441fc4da09",
    title: 'Pakiet "Free"',
    price: '0 zł / rok',
    features: [
      "1 urządzenie w pakiecie",
      "Powiadomienia e-mail o znalezionym urządzeniu",
      "Ważność kodu 1 miesiąc",
      "Lokalizacja urządzenia Android",
      "Częstotliwość pobierania lokalizacji co 30 min.",
      "Zdalna aktualizacja ekranu blokady Android",
    ],
    isPopular: false,
  },
    {
      uuid: "6750ea40-cf8d-4396-be09-6d441fc4da09",
      title: 'Pakiet "Rodzinny"',
      price: '39,90 zł / rok',
      features: [
        "6 urządzeń w pakiecie",
        "Powiadomienia e-mail o znalezionym urządzeniu",
        "Powiadomienia sms o znalezionym urządzeniu",
        "Ważność kodów 12 miesięcy z możliwością odnowienia na kolejny okres",
        "Pomoc online",
        "Lokalizacja urządzenia Android",
        "Częstotliwość pobierania lokalizacji co 1 min.",
        "Zdalna aktualizacja ekranu blokady Android",
      ],
      isPopular: true,
    },
    {
      uuid: "8742db1f-563f-4398-9943-7a913cfcc5e9",
      title: 'Pakiet "Tylko my"',
      price: '12,90 zł / rok',
      features: [
        "2 urządzenia w pakiecie",
        "Powiadomienia e-mail o znalezionym urządzeniu",
        "Powiadomienia sms o znalezionym urządzeniu",
        "Ważność kodów 12 miesięcy z możliwością odnowienia na kolejny okres",
        "Lokalizacja urządzenia Android",
        "Częstotliwość pobierania lokalizacji co 1 min.",
        "Zdalna aktualizacja ekranu blokady Android",
      ],
      isPopular: false,
    },
    {
      uuid: "5e882806-36a5-41e2-89a6-512f9958115e",
      title: 'Pakiet "Firmowy"',
      price: '109,90 zł / rok',
      features: [
        "20 urządzeń w pakiecie",
        "Powiadomienia e-mail o znalezionym urządzeniu",
        "Powiadomienia sms o znalezionym urządzeniu",
        "Ważność kodów 12 miesięcy z możliwością odnowienia na kolejny okres",
        "Możliwość zwiększenia ilości urządzeń",
        "Pomoc online",
        "Lokalizacja urządzenia Android",
        "Częstotliwość pobierania lokalizacji co 1 min.",
        "Zdalna aktualizacja ekranu blokady Android",
      ],
      isPopular: false,
    },
  ];