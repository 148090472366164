import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LoginPage from "../../../Login/LoginPage";
import {
  Card,
  Space,
  Row,
  Col,
  Breadcrumb,
  Descriptions,
  message,
  Tag,
  Divider,
  Spin,
  Alert,
} from "antd";
import {
  isLogged,
  destroyAuthToken,
  getAuthToken,
} from "../../../../helpers/account";
import { UserOutlined, HomeOutlined } from "@ant-design/icons";
import { api } from "../../../../config/api";
import EditAccountForm from "./Forms/EditAccountForm";
import { useSelector } from "react-redux";

function AccountDataPage() {
  const [data, setData] = useState([]); // Change initial state to []
  const [loading, setLoading] = useState(true);
  const accountDataChanged = useSelector((state) => state.accountDataChanged);

  useEffect(() => {
    // Your API endpoint URL goes here
    const apiUrl = `${api.host}/accounts/${sessionStorage.getItem("uuid")}`;

    if (sessionStorage.getItem("authToken")) {
      // Fetch data from the API only if authToken exists

      // Fetch data from the API
      fetch(apiUrl, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `${getAuthToken()}`, // Replace "Bearer" if your API expects a different prefix
        }),
      })
        .then((response) => response.json())
        .then((fetchedData) => {
          setData(fetchedData); // Set the data state with fetched data
          setLoading(false); // Set loading to false
        })
        .catch((error) => {
          setTimeout(() => {
            message.error("Dla bezpieczeństwa zostałeś wylogowany.");
            destroyAuthToken();
            window.location.href = "/devices";
          }, 2000);
          setLoading(false); // Set loading to false even on error
        });
    }
  }, [accountDataChanged]); // Remove "data" from the dependency array

  if (isLogged()) {
    return (
      <>
        <Row
           style={{
            minHeight: "100vh",
          }}
        >
          <Col
            span={24}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
          
           
              <Spin spinning={loading}>
                {" "}
                {/* Wrap the Spin component here */}
                <Card title={<> <img src="/img/id.png" /> Dane teleadresowe</>}>
                  <Space size={30} direction="vertical" style={{maxWidth:"800px"}}>
                  <Breadcrumb
                items={[
                  {
                    href: "/devices",
                    title: <HomeOutlined />,
                  },
                  {
                    href: "/account",
                    title: "Moje Dane",
                  },
                  {
                    title: "Numer telefonu i adres e-mail",
                  },
                ]}
              />
                    <EditAccountForm />
                  
                    <Descriptions layout="vertical">
                      <Descriptions.Item label="Imię i nazwisko">
                        {data.fullname ? data.fullname : "(nie podano)"}
                      </Descriptions.Item>

                      <Descriptions.Item label="Adres">
                        <Space>
                          {data.address ? data.address : "(nie podano)"} {data.post_code} {data.city}
                        </Space>
                      </Descriptions.Item>
                      <Descriptions.Item label="NIP">
                        <Space>
                          {data.nip ? data.nip : "(nie podano)"} {data.company_name}
                        </Space>
                      </Descriptions.Item>
                      <Divider></Divider>
                      <Descriptions.Item label="Telefon">
                        <Space>
                          {data.mobile ? data.mobile : "(nie podano)"}{" "}
                          {data.mobile_confirmed ? (
                            <Tag color="green">Zweryfikowany</Tag>
                          ) : (
                            <Tag color="magenta">Niezweryfikowany</Tag>
                          )}
                        </Space>
                      </Descriptions.Item>
                      <Descriptions.Item label="E-mail">
                        <Space>
                          {data.email}{" "}
                          {data.email_confirmed ? (
                            <Tag color="green">Zweryfikowany</Tag>
                          ) : (
                            <Tag color="magenta">Niezweryfikowany</Tag>
                          )}
                        </Space>
                      </Descriptions.Item>
            
                    </Descriptions>
                  </Space>
                </Card>
              </Spin>{" "}
              {/* Close the Spin component here */}
          
          </Col>
        </Row>
      </>
    );
  } else {
    return <LoginPage></LoginPage>;
  }
}

export default AccountDataPage;
