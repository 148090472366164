/**
 * Determine the role of the logged-in account in a transaction.
 * @param {object} transaction - The transaction object.
 * @returns {number} - The role ID (1 or 2).
 */
export function getLoggedAccountTransactionRoleId(transaction) {
    if (sessionStorage.getItem("uuid") === transaction.transaction_initiator.uuid) {
        return transaction.initiator_role.id;
    }

    if (sessionStorage.getItem("uuid") !== transaction.transaction_initiator.uuid &&
        transaction.initiator_role.id === 1) {
        return 2;
    }

    if (sessionStorage.getItem("uuid") !== transaction.transaction_initiator.uuid &&
        transaction.initiator_role.id === 2) {
        return 1;
    }
}


/**
 * Determine the role of the account in a transaction.
 * @param {object} transaction - The transaction object.
 * @returns {number} - The role ID (1 or 2).
 */
export function getAccountTransactionRoleId(transaction, account) {
    if (account.uuid === transaction.transaction_initiator.uuid) {
        return transaction.initiator_role.id;
    }

    if (account.uuid !== transaction.transaction_initiator.uuid &&
        transaction.initiator_role.id === 1) {
        return 2;
    }

    if (account.uuid !== transaction.transaction_initiator.uuid &&
        transaction.initiator_role.id === 2) {
        return 1;
    }
}

/**
 * Check if the transaction was initiated by the logged-in account.
 * @param {object} transaction - The transaction object.
 * @returns {boolean} - True if initiated by the logged-in account, false otherwise.
 */
export function isTransactionInitiatedByLoggedAccount(transaction) {
    if (transaction.transaction_initiator) {
        return sessionStorage.getItem("uuid") === transaction.transaction_initiator.uuid;
    }
    return false;
}

/**
 * Check if the user is logged in.
 * @returns {boolean} - True if the user is logged in, false otherwise.
 */
export function isLogged() {
    return sessionStorage.getItem("authToken") ? true : false;
}

/**
 * Get the authentication token if the user is logged in.
 * @returns {string} - The authentication token or an empty string if not logged in.
 */
export function getAuthToken() {
    return isLogged() ? sessionStorage.getItem("authToken") : '';
}

/**
 * Clears the authentication token and UUID from sessionStorage.
 */
export function destroyAuthToken() {
    sessionStorage.clear();
    localStorage.clear();

}
