import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'antd';

const OfferTestPopup = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const startTime = Date.now();

    const checkPopupDisplay = () => {
      const sessionStartTime = sessionStorage.getItem('popupStartTime');
      const elapsedTime = sessionStartTime
        ? Date.now() - sessionStartTime
        : Date.now() - startTime;

      const userResponse = localStorage.getItem('popupResponse');
      if (userResponse) {
        // User has already responded, do not show the popup
        return;
      }

      if (elapsedTime >= 40000) {
        setVisible(true);
        logGAEvent('popup_shown', {
          event_category: 'engagement',
          event_label: 'Popup shown',
          value: 1,
        });
      } else {
        setTimeout(checkPopupDisplay, 1000);
      }
    };

    sessionStorage.setItem('popupStartTime', Date.now() - (sessionStorage.getItem('totalTimeSpent') || 0));
    setTimeout(checkPopupDisplay, 1000);

    return () => {
      const timeSpent = Date.now() - startTime;
      const totalTimeSpent = (parseInt(sessionStorage.getItem('totalTimeSpent'), 10) || 0) + timeSpent;
      sessionStorage.setItem('totalTimeSpent', totalTimeSpent);
    };
  }, []);

  const handlePopupClose = (response) => {
    setVisible(false);
    localStorage.setItem('popupResponse', response);
    logGAEvent('popup_response', {
      event_category: 'engagement',
      event_label: `Popup response: ${response}`,
      value: 1,
    });
  };

  const logGAEvent = (eventName, eventParams) => {
    if (window.gtag) {
      window.gtag('event', eventName, eventParams);
    }
  };

  return (
    <Modal
      title="Czy oferta jest dla Ciebie zrozumiała?"
      visible={visible}
      onCancel={() => handlePopupClose('No response')}
      footer={[
        <Button key="yes" onClick={() => handlePopupClose('Tak')}>
          Tak
        </Button>,
                <Button key="mid" onClick={() => handlePopupClose('Średnio')}>
                Średnio
              </Button>,
        <Button key="no" onClick={() => handlePopupClose('Nie')}>
          Nie
        </Button>,
      ]}
    >
      <p>Prosimy o odpowiedź, abyśmy mogli ulepszyć naszą ofertę.</p>
    </Modal>
  );
};

export default OfferTestPopup;
