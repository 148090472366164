import React, { useEffect, useState } from "react";
import { Modal, List, Button, message, Progress, Space } from "antd";
import { formatPolishDate } from "../../../helpers/date";
import {
  isLogged,
  getAuthToken,
  destroyAuthToken,
} from "../../../helpers/account";
import { api } from "../../../config/api";
import MapFactory from "./Map";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { fromLonLat } from "ol/proj";
import {
  Style,
  Icon,
  Text,
  Fill,
  Stroke,
  Circle as CircleStyle,
} from "ol/style";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import Cluster from "ol/source/Cluster";
import { ThunderboltOutlined } from "@ant-design/icons";

const LocationPopup = ({ visible, onClose, device }) => {
  const [map, setMap] = useState(null);
  const [features, setFeatures] = useState([]);
  const [locations, setLocations] = useState([]);
  const [progress, setProgress] = useState(0);
  const [clusterLayer, setClusterLayer] = useState(null); // New state for cluster layer

  const getDeviceIcon = (deviceType) => {
    switch (deviceType) {
      case 1:
        return (
          <img
            src="/img/mobile-phone.png"
            alt="Mobile"
            style={{ width: 24, height: 24 }}
          />
        );
      case 2:
        return (
          <img
            src="/img/tablet.png"
            alt="Tablet"
            style={{ width: 24, height: 24 }}
          />
        );
      case 3:
        return (
          <img
            src="/img/laptop.png"
            alt="Laptop"
            style={{ width: 24, height: 24 }}
          />
        );
      case 4:
      default:
        return (
          <img
            src="/img/pendrive.png"
            alt="Other"
            style={{ width: 24, height: 24 }}
          />
        );
    }
  };

  const fetchOpenStreetPlaceData = async (placeCoordinates) => {
    const headers = new Headers();
    headers.append("User-Agent", "odpado.pl - A nature supporting app.");

    const options = {
      headers: headers,
    };

    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=geojson&lat=${placeCoordinates.latitude}&lon=${placeCoordinates.longitude}`,
      options
    );

    if (!response.ok) {
      throw new Error("Data could not be fetched!");
    } else {
      return response.json();
    }
  };

  // Fetch locations from the API
  const getLocations = async (device) => {
    try {
      const response = await fetch(
        `${api.host}/devices/location/${device.uuid}`,
        {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `${getAuthToken()}`,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch locations");
      }

      const data = await response.json();
      setLocations(data);
    } catch (error) {
      message.error("Failed to fetch locations.");
    }
  };

  // Handle the progress bar and automatic refresh
  useEffect(() => {
    if (visible) {
      getLocations(device);

      // Set up an interval to refresh the data every 60 seconds
      const intervalId = setInterval(() => {
        getLocations(device);
        setProgress(0);
      }, 60000);

      // Update the progress bar every second
      const progressIntervalId = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress < 100 ? prevProgress + 1.67 : 0
        );
      }, 1000);

      // Clear the intervals when the component unmounts or the device changes
      return () => {
        clearInterval(intervalId);
        clearInterval(progressIntervalId);
      };
    }
  }, [device, visible]);

  // Handle clicking on a list item to zoom to the marker on the map
  const handleMapClick = (location) => {
    if (map) {
      const feature = features.find(
        (f) => f.get("id") === location.device_uuid
      );

      if (feature) {
        const view = map.map.getView();
        const coords = fromLonLat([location.longitude, location.latitude]);
        view.setCenter(coords);
        view.setZoom(24);

        feature.setStyle(
          new Style({
            image: new Icon({
              anchor: [0.5, 1],
              src: "/img/map-pin-highlight.png",
            }),
            text: new Text({
              font: "12px sans-serif",
              text: formatPolishDate(location.timestamp),
              offsetY: -25,
              fill: new Fill({ color: "#ff0000" }),
              stroke: new Stroke({ color: "#fff", width: 2 }),
            }),
          })
        );
      }
    }
  };

  // Initialize the map when the modal becomes visible
  useEffect(() => {
    if (visible && !map) {
      const mapInstance = new MapFactory();
      setMap(mapInstance);

      const mapElement = document.getElementById("map");

      if (mapElement) {
        mapElement.innerHTML = "";
        mapInstance.initialize([19.1203094, 50.8118195]);
        mapInstance.map.getView().setZoom(6);
      }
    }
  }, [visible, map]);

  // Update the map with the new locations whenever they change
  useEffect(() => {
    if (map && visible && locations.length > 0) {
      // Clear the previous cluster layer if it exists
      if (clusterLayer) {
        map.map.removeLayer(clusterLayer);
      }

      const newFeatures = locations.map((location) => {
        return new Feature({
          geometry: new Point(
            fromLonLat([location.longitude, location.latitude])
          ),
          label: formatPolishDate(location.timestamp),
          id: location.device_uuid,
        });
      });

      const clusterSource = new Cluster({
        distance: 40,
        source: new VectorSource({
          features: newFeatures,
        }),
      });

      const newClusterLayer = new VectorLayer({
        source: clusterSource,
        style: function (feature) {
          const size = feature.get("features").length;
          let style;

          if (size > 1) {
            style = new Style({
              image: new CircleStyle({
                radius: 15,
                fill: new Fill({
                  color: "#3399CC",
                }),
                stroke: new Stroke({
                  color: "#fff",
                  width: 2,
                }),
              }),
              text: new Text({
                text: size.toString(),
                fill: new Fill({
                  color: "#fff",
                }),
              }),
            });
          } else {
            const singleFeature = feature.get("features")[0];
            style = new Style({
              image: new Icon({
                anchor: [0.5, 1],
                src: "/img/map-pin.png",
              }),
              text: new Text({
                font: "12px sans-serif",
                text: singleFeature.get("label"),
                offsetY: -25,
                fill: new Fill({ color: "#000" }),
                stroke: new Stroke({ color: "#fff", width: 2 }),
              }),
            });
          }
          return style;
        },
      });

      map.map.addLayer(newClusterLayer);
      setClusterLayer(newClusterLayer); // Save the new cluster layer in state
      setFeatures(newFeatures);

      const newestLocation = locations[0];
      const view = map.map.getView();
      const coords = fromLonLat([
        newestLocation.longitude,
        newestLocation.latitude,
      ]);
      view.setCenter(coords);
      view.setZoom(18);
    }
  }, [map, locations, visible]);

  return (
    <Modal
      title={
        device ? (
          <>
            <h1 style={{ margin: 0 }}>
              {getDeviceIcon(device.device_type_id)} {device.name}
            </h1>
          </>
        ) : (
          <></>
        )
      }
      open={visible}
      onCancel={onClose}
      footer={null}
      width={600}
    >
      <div
        id="map"
        className="ol-map"
        style={{ height: 400, marginBottom: 20 }}
      ></div>
      <Button onClick={() => getLocations(device)} style={{ marginBottom: 20 }}>
        Odśwież mapę
      </Button>
      <Progress
        percent={progress}
        status="active"
        showInfo={false}
        style={{ marginBottom: 20 }}
      />
      <div style={{ height: 200, marginBottom: 20, overflowY: "scroll" }}>
        <List
          itemLayout="horizontal"
          dataSource={locations}
          renderItem={(item) => (
            <List.Item
              onClick={() => handleMapClick(item)}
              style={{ cursor: "pointer" }}
            >
              <List.Item.Meta
                title={`${formatPolishDate(item.timestamp)}`}
                description={
                  <>
                    <Space>
                    Położenie: {item.latitude}, {item.longitude}{" "}
                    </Space>
                    <ThunderboltOutlined /> Bateria: {item.batteryLevel}%
                    
                  </>
                }
              />
            </List.Item>
          )}
        />
      </div>
    </Modal>
  );
};

export default LocationPopup;
