import { Button, Modal } from "antd";
import React, { useState, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const AboutPage = () => {
  const [visible, setVisible] = useState(null);

  return (
    <>
      {" "}
      <Helmet>
        <title>Cell ID - Otwarty rejestr urządzeń mobilnych</title>
      </Helmet>
      <div className="hero-background">
        <div className="hero-background-headline">
          <div>
            <img src="/img/people.svg" />
          </div>
          Łączymy
          <br />
          urządzenia z kontaktem
          <br />
          do właściciela
        </div>
      </div>
      <div className="abouttxt">
        <p>
          CELL ID to usługa w chmurze z prostym ale skutecznym przesłaniem -
          umożliwić znalazcy odnalezienie właściciela urządzenia.
        </p>
        <h3>Ale jak to się zaczęło?</h3>
        <p>
          Uwaga - historia prawdziwa 🙂 Ostatnio mój bąbelek wrócił z wycieczki
          do Międzyzdrojów bez telefonu. Miał Family Link, który działał... aż
          skończył się internet. Telefon został na straganie z pierdołami –
          płacz był srogi, bo rekordy w Brawl Stars przepadły, a kontakty do
          dwóch przyjaciółek-sąsiadek zniknęły jak sen złoty.
        </p>
        <p>
          Dzwoniłem na ten telefon milion razy, bo dzwonił, ale Pani ze
          straganu, w tym turystycznym harmidrze, nic nie słyszała. W końcu,
          przez przypadek, znalazła go w jakiejś szufladce z muszelkami. Telefon
          był zabezpieczony pinem (te łączone kropki), ale Pani okazała się
          hardkorowcem – rozkminiała ten pin przez kilka dni i w końcu się
          udało. Tu zdobyła moje uznanie, bo te kropki blokują się przy każdej
          nieudanej próbie na ileś tam sekund. No i oddzwoniła do mnie. Wysłałem
          forsę na przesyłkę i czekoladę Blikiem, telefon wrócił, bąbelek
          zadowolony. Happy end. Wszystko dzięki tej hardkorowej Pani.
        </p>
        <p>
          Wtedy pomyślałem, że te piny to przekleństwo 🙂 Ale każdy lubi mieć (i
          czasami musi).
        </p>
        <p>
          Dlatego wpadłem na pomysł na serwis, na którym możecie wygenerować
          tapetę z QR kodem i ustawić ją na ekranie blokady. Znalazca skanuje
          kod i od razu widzi wizytówkę właściciela. Na ekranie blokady w
          Androidzie można ustawić dowolny tekst, ale QR kod jest o tyle lepszy,
          że jeśli masz kilka telefonów, to nie musisz ustawiać każdego z osobna
          – wszystko zrobisz z jednego miejsca w panelu CellID. No i jeśli
          zgubią się dwa telefony na plaży, to numer tego wpisanego jako
          ratunkowy już nie jest ratunkowy, bo... również został na plaży.
        </p>
        <p>
        Co najważniejsze – pomysł został bardzo ciepło przyjęty w społeczności, co dodaje mi chęci do działania.
        </p>
        <h3>Kontakt</h3>
        <p>
          CELL ID
          <br />
          ul. Wolności 64B
          <br />
          73-200 Choszczno
          <br />
          +48 530 858 861
        </p>
      </div>
      <div className="ceo">
        <p>
          {" "}
          Stworzyłem usługę CELL ID w formie parasola ochronnego dla Twoich
          urządzeń mobilnych. Chcę minimalizować ryzyko utraty Twoich urządzeń,
          umożliwiając szybkie i łatwe odzyskanie, łącząc znalazców z
          właścicielami urządzeń. Wybierz CELL ID i ciesz się spokojem, wiedząc,
          że Twoje urządzenia są bezpieczne.
        </p>
        <div className="photo">
          <img src="/img/3.svg" alt="CEO" />
        </div>
        <div className="sign">
          <img src="/img/4.svg" alt="Signature" />
        </div>
        <div className="postpay">Twórca Rejestru CELL ID</div>
      </div>
      <div className="partners">
        <img src="/img/14.svg" />
      </div>
    </>
  );
};

export default AboutPage;
