import React, { useState, useEffect } from "react";
import { Table, Spin, Alert, Card } from "antd";
import { api } from "../../../../config/api";
import {
  isLogged,
  getAuthToken,
} from "../../../../helpers/account";
import { useSelector } from "react-redux";

const parseResourceString = (resourceString) => {
  const match = resourceString.match(/\("([^"]+)",(\d+),(\d+),(-?\d+)\)/);
  if (match) {
    return {
      resource: match[1],
      total: parseInt(match[2], 10),
      used: parseInt(match[3], 10),
      remaining: parseInt(match[4], 10),
    };
  }
  return null;
};

const ResourcesUsage = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [resources, setResources] = useState([]);
  const transactionCreated = useSelector((state) => state.transactionCreated);

  useEffect(() => {
    const fetchResources = async () => {
      try {
        const apiUrl = `${api.host}/accounts/plans/resources`; // Update with your API endpoint
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${getAuthToken()}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch resources");
        }

        const data = await response.json();
        const parsedResources = data.resources.map((item) =>
          parseResourceString(item.check_resources_left)
        );
        setResources(parsedResources);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch resources");
        setLoading(false);
      }
    };

    fetchResources();
  }, [transactionCreated]);

  const columns = [
    {
      title: "Nazwa",
      dataIndex: "resource",
      key: "resource",
    },
    {
      title: "Ilość",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Wykorzystano",
      dataIndex: "used",
      key: "used",
    },
    {
      title: "Pozostało",
      dataIndex: "remaining",
      key: "remaining",
    },
  ];

  if (loading) {
    return <Spin tip="Loading..." />;
  }

  if (error) {
    return <Alert message="Error" description={error} type="error" />;
  }

  return (
    <Card title={<> <img src="/img/money.png" /> Wykorzystanie pakietu</>}>
      <Table
        size="small"
        columns={columns}
        dataSource={resources}
        rowKey={(record) => record.resource}
      />
    </Card>
  );
};

export default ResourcesUsage;
