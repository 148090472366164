import React, { useEffect, useState } from "react";
import { Card, Input, Space, Button, Row, Col, message, Divider } from "antd";
import {
  UserOutlined,
  BlockOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { api } from "../../config/api";
import { destroyAuthToken, getAuthToken } from "../../helpers/account";
import { isPasswordSafe } from "../../helpers/validators";
import { useParams, Link } from "react-router-dom";

const AcceptInvitationPage = () => {
  const [email, setEmail] = useState("");
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const [codeVerifed, setCodeVerifed] = useState(false);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(true);
  const [code, setCode] = useState("");
  const [confirmPassword, setConfirmPassword] = useState(""); // New state for confirmPassword
  const [messageApi, contextHolder] = message.useMessage();
  const { invitationUuid } = useParams();

  useEffect(() => {
    // Your API endpoint URL goes here
    const apiUrl = `${api.host}/accounts/find-email-by-invitation-uuid`; // Update with your API endpoint

    const requestBody = {
      uuid: invitationUuid,
    };

    // Fetch data from the API
    fetch(apiUrl, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        setEmail(data.email); // Update the state with the fetched data
        setLoading(false); // Set loading to false
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: "Zaproszenie nie zostało odnalezione",
        });
        setLoading(false); // Set loading to false even on error
      });
  }, [invitationUuid]);

  const handleSendCode = () => {
    // Your API endpoint URL goes here
    const apiUrl = `${api.host}/accounts/initiate-email-confirmation`;

    // Define your request body (you may need to adjust this based on your API)
    const requestBody = {
      email,
    };

    fetch(apiUrl, {
      method: "POST", // Use 'POST' if your API requires it
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (response.status === 200) {
          // Parse the JSON response body
          response
            .json()
            .then((data) => {
              setEmailConfirmed(true);
              messageApi.open({
                type: "success",
                content: "Kod autoryzujący został wysłany na adres e-mail",
              });
            })
            .catch((error) => {
              messageApi.open({
                type: "error",
                content:
                  "Wystąpił błąd w akcji generowania kodu autoryzującego",
              });
            });
        } else {
          messageApi.open({
            type: "warning",
            content: "Błędny adres e-mail",
          });
          destroyAuthToken();
        }
      })
      .catch((error) => {
        messageApi.open({
          type: "warning",
          content: "Błędny adres e-mail",
        });
        destroyAuthToken();
      });
  };

  const handleVerification = () => {
    if (code == "") {
      messageApi.open({
        type: "error",
        content: "Nie przepisałeś kodu weryfikacyjnego.",
      });
      return; // Exit the function early if passwords don't match
    }

    // Your API endpoint URL goes here
    const apiUrl = `${api.host}/accounts/email-confirmation`;

    // Define your request body (you may need to adjust this based on your API)
    const requestBody = {
      email,
      code,
    };

    fetch(apiUrl, {
      method: "POST", // Use 'POST' if your API requires it
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (response.status === 200) {
          setCodeVerifed(true);

          response
            .json()
            .then((data) => {
              messageApi.open({
                type: "success",
                content: "Konto zostało zweryfikowane.",
              });
              // Save authToken in session storage
              sessionStorage.setItem("authToken", data.authToken);
              sessionStorage.setItem("uuid", data.uuid);
            })
            .catch((error) => {});
        }
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: "Niepoprawny kod weryfikacyjny. Spróbuj jeszcze raz.",
        });
      });
  };

  const handleReset = () => {
    // Check if passwords match
    if (password !== confirmPassword) {
      messageApi.open({
        type: "error",
        content: "Hasła nie pasują do siebie. Spróbuj ponownie.",
      });
      return; // Exit the function early if passwords don't match
    }

    if (!isPasswordSafe(password)) {
      messageApi.open({
        type: "error",
        content:
          "Hasło musi mieć co najmniej 8 znaków i zawierać przynajmniej jedną wielką literę, jedną cyfrę i jeden znak specjalny (!@#$%^&*).",
      });
      return; // Exit the function early if password doesn't meet requirements
    }

    // Your API endpoint URL goes here
    const apiUrl = `${api.host}/accounts/reset-password`;

    // Define your request body (you may need to adjust this based on your API)
    const requestBody = {
      newPassword: password,
    };

    fetch(apiUrl, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `${getAuthToken()}`,
      }),
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (response.status === 200) {
          messageApi.open({
            type: "success",
            content: "Hasło do konta zostało ustawione.",
          });
        }
        destroyAuthToken();
        setTimeout(() => {
          window.location.href = "/devices";
        }, 2000);
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: "Wystąpiły błędy podczas akcji akceptacji zaproszenia.",
        });
        destroyAuthToken();
        setTimeout(() => {
          window.location.href = "/devices";
        }, 2000);
      });
  };

  return (
    <Row
      style={{
        height: 500,

        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      {contextHolder}
      <Col
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
        }}
      >
        <Card
          title={
            <>
              <UserOutlined /> Akceptacja Zaproszenia
            </>
          }
          extra={
            <Link to="/">
              <CloseCircleOutlined style={{ color: "#8c8c8c" }} />
            </Link>
          }
          style={{
            width: 350,
            boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
          }}
        >
          <Space size={30} direction="vertical">
            <Input
              size="large"
              autoComplete="off"
              placeholder="Adres e-mail"
              prefix={<UserOutlined />}
              value={email}
              disabled={true}
              onChange={(e) => setEmail(e.target.value)}
            />

            {emailConfirmed === true && codeVerifed === false ? (
              <>
                {" "}
                <p>
                  Przepisz otrzymany kod weryfikacyjny, który wysłaliśmy na
                  podany adres e-mail.
                </p>
                <Input
                  size="large"
                  autoComplete="off"
                  placeholder="Kod weryfikacyjny"
                  prefix={<BlockOutlined />}
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
                <Button type="primary" onClick={handleVerification}>
                  Zweryfikuj adres e-mail
                </Button>
              </>
            ) : (
              <></>
            )}

            {codeVerifed ? (
              <>
                {" "}
                <Input.Password
                  size="large"
                  autoComplete="off"
                  placeholder="Hasło"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Input.Password
                  size="large"
                  autoComplete="off"
                  placeholder="Powtórz hasło"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)} // Set confirmPassword
                />
                <Button type="primary" onClick={handleReset}>
                  Ustaw hasło do konta
                </Button>
              </>
            ) : (
              <></>
            )}

            {emailConfirmed === false && codeVerifed === false ? (
              <>
                {" "}
                <Button type="primary" onClick={handleSendCode}>
                  Wyślij kod na e-mail
                </Button>
              </>
            ) : (
              <></>
            )}
          </Space>
        </Card>
      </Col>
    </Row>
  );
};

export default AcceptInvitationPage;
