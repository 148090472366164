import React, { useState, useEffect } from "react";
import { Space, Button, Divider, Modal, Input, message, Spin } from "antd";
import {
  isLogged,
  getAuthToken,
  destroyAuthToken,
} from "../../../../../helpers/account";
import { api } from "../../../../../config/api";
import { useDispatch } from "react-redux";

const EditAccountForm = (props) => {
  const [editAccountModalOpen, setEditAccountModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(false);

  useEffect(() => {
    if (formData === false && sessionStorage.getItem("authToken")) {
      const apiUrl = `${api.host}/accounts/${sessionStorage.getItem("uuid")}`;
      // Fetch data from the API
      fetch(apiUrl, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `${getAuthToken()}`, // Replace "Bearer" if your API expects a different prefix
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setFormData(data);
          setLoading(false); // Set loading to false
        })
        .catch((error) => {
          setTimeout(() => {
            message.error("Dla bezpieczeństwa zostałeś wylogowany.");
            destroyAuthToken();
            window.location.href = "/devices";
          }, 2000);
          setLoading(false); // Set loading to false even on error
        });
    }
  });

  const handleUpdateAccount = () => {
    let apiData = {
      fullname: formData.fullname,
      mobile: formData.mobile,
      company_name: formData.company_name,
      address: formData.address,
      post_code: formData.post_code,
      city: formData.city,
      nip: formData.nip,
    };

    const apiUrl = `${api.host}/accounts/${formData.uuid}`;

    if (isLogged()) {
      setLoading(true);
      // Fetch data from the API
      fetch(apiUrl, {
        method: "PUT",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `${getAuthToken()}`, // Replace with your authorization method
        }),
        body: JSON.stringify(apiData), // Convert the object to JSON and send in the request body
      })
        .then((response) => response.json())
        .then((data) => {
          dispatch({
            type: "accountDataChanged",
            payload: {
              accountDataChanged: new Date().getSeconds(),
            },
          });
          // Handle the response data as needed
          message.success("Dane konta zostały zaktualizowane.");
          setEditAccountModalOpen(false);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error updating account:", error);
        });
    }
  };

  const handleEditAccount = () => {
    setEditAccountModalOpen(true);
  };

  const handleCancelEditAccount = () => {
    setEditAccountModalOpen(false);
  };

  return (
    <>
      <Button onClick={handleEditAccount} type="primary">
        Zmiana danych
      </Button>

      <Modal
        title="Zmiana danych konta"
        open={editAccountModalOpen} // Zmieniłem "open" na "visible"
        onOk={handleUpdateAccount}
        onCancel={handleCancelEditAccount}
        okText="Zaktualizuj"
        cancelText="Anuluj"
      >
        <Spin spinning={loading}>
          <Space size={15} direction="vertical" style={{ width: "100%" }}>
            <Input
              placeholder="Imię i nazwisko"
              onChange={(e) =>
                setFormData({ ...formData, fullname: e.target.value })
              }
              value={formData.fullname}
            />
            <Input
              placeholder="Nazwa firmy"
              onChange={(e) =>
                setFormData({ ...formData, company_name: e.target.value })
              }
              value={formData.company_name}
            />
            <br />
            <Input
              placeholder="Telefon"
              onChange={(e) =>
                setFormData({ ...formData, mobile: e.target.value })
              }
              value={formData.mobile}
            />
            <br />
            <Input
              placeholder="Adres"
              onChange={(e) =>
                setFormData({ ...formData, address: e.target.value })
              }
              value={formData.address}
            />
            <Space>
              <Input
                placeholder="Kod pocztowy"
                onChange={(e) =>
                  setFormData({ ...formData, post_code: e.target.value })
                }
                value={formData.post_code}
              />
              <Input
                placeholder="Miasto"
                onChange={(e) =>
                  setFormData({ ...formData, city: e.target.value })
                }
                value={formData.city}
              />
            </Space>
            <br />
            <Input
              placeholder="NIP"
              onChange={(e) =>
                setFormData({ ...formData, nip: e.target.value })
              }
              value={formData.nip}
            />
          </Space>
        </Spin>
      </Modal>
    </>
  );
};

export default EditAccountForm;
