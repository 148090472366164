import React, { useState, useEffect, useRef } from "react";
import {
  Alert,
  Button,
  Modal,
  Space,
  Card,
  Typography,
  Spin,
  Radio,
  message,
  Select,
  Slider,
  Tabs,
} from "antd";

import QRCode from "qrcode.react";
import { isLogged, getAuthToken } from "../../../helpers/account";
import { Link } from "react-router-dom";
import { api } from "../../../config/api";
import Resizer from "react-image-file-resizer"; // Import the Resizer library
import { faker } from "@faker-js/faker";

const { Text } = Typography;
const { Option } = Select;

const downloadQRCode = (qrCodeRef) => {
  const qrCodeCanvas = qrCodeRef.current.children[0];
  if (qrCodeCanvas) {
    const url = qrCodeCanvas.toDataURL("image/png");
    const a = document.createElement("a");
    a.download = "QRCode.png";
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};

const QRCodePopup = ({ visible, onClose, qrCodeUrl, device }) => {
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [ownerAvatar, setOwnerAvatar] = useState("/img/cellid_logo.svg"); // New state for owner avatar
  const [currentPlan, setCurrentPlan] = useState(false);
  const [qrBackground, setQrBackground] = useState("transparent"); // State for QR background
  const [qrColor, setQrColor] = useState("black"); // State for QR background
  const canvasRef = useRef(null);
  const qrCodeRef = useRef(null);
  const [loading, setLoading] = useState(false); // Loading state
  const [messageApi, contextHolder] = message.useMessage();
  const [qrPosition, setQrPosition] = useState("center"); // State for QR position
  const [qrCodeSize, setQrCodeSize] = useState(200);
  const [uploading, setUploading] = useState(false); // Uploading state
  const [sending, setSending] = useState(false); // Sending state
  const [category, setCategory] = useState("abstract");

  // Image categories with Polish labels
  const categories = [
    { value: "abstract", label: "Abstrakcja" },
    { value: "animals", label: "Zwierzęta" },
    { value: "business", label: "Biznes" },
    { value: "cats", label: "Koty" },
    { value: "city", label: "Miasto" },
    { value: "fashion", label: "Moda" },
    { value: "food", label: "Jedzenie" },
    { value: "nature", label: "Natura" },
    { value: "nightlife", label: "Nocne życie" },
    { value: "people", label: "Ludzie" },
    { value: "sports", label: "Sport" },
    { value: "technics", label: "Technika" },
    { value: "transport", label: "Transport" },
  ];

  function extractBase64Data(dataUri) {
    // Check if the data URI contains the base64 prefix
    const base64Prefix = "base64,";
    const prefixIndex = dataUri.indexOf(base64Prefix);

    // If the prefix is found, extract the base64 data after the prefix
    if (prefixIndex !== -1) {
      return dataUri.substring(prefixIndex + base64Prefix.length);
    }

    // If the prefix is not found, return the original string (it may already be clean base64)
    return dataUri;
  }

  const downloadMergedImage = (canvasRef, setLoading, callback) => {
    messageApi.open({
      type: "success",
      content: "Robimy robotę! Twoja tapeta będzie za chwilę gotowa.",
    });

    setLoading(true);

    setTimeout(() => {
      const canvas = canvasRef.current;
      if (canvas) {
        const url = canvas.toDataURL("image/png");
        if (callback) {
          callback(extractBase64Data(url)); // Pass the base64 image to the callback function
        }
      }
      setLoading(false);
    }, 10000);
  };

  useEffect(() => {
    if (isLogged()) {
      fetch(`${api.host}/accounts/plans/current`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `${getAuthToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setCurrentPlan(data.currentPlan.plan);
        });
    }
  }, []);

  const handleSetWallpaper = (deviceUuid) => {
    if (isLogged()) {
      downloadMergedImage(canvasRef, setLoading, (base64Image) => {
        setUploading(true);
        fetch(`${api.host}/upload`, {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `${getAuthToken()}`,
          }),
          body: JSON.stringify({ image: base64Image }),
        })
          .then((res) => res.json())
          .then((data) => {
            setUploading(false);
            setSending(true);
            fetch(`${api.host}/devices/wallpaper/${deviceUuid}/${data.uuid}`, {
              method: "POST",
              headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `${getAuthToken()}`,
              }),
            })
              .then((res) => res.json())
              .then((data) => {
                setSending(false);
                messageApi.open({
                  type: "success",
                  content: "Tapeta została ustawiona w urządzeniu.",
                });
              })
              .catch((error) => {
                setSending(false);
                messageApi.open({
                  type: "error",
                  content: "Błąd podczas ustawiania tapety na urządzeniu.",
                });
              });
          })
          .catch((error) => {
            setUploading(false);
            messageApi.open({
              type: "error",
              content: "Błąd podczas przesyłania tapety.",
            });
          });
      });
    }
  };
  const handleBackgroundUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      Resizer.imageFileResizer(
        file,
        1000, // Maximum width for popular screen resolutions
        1000, // Maximum height for popular screen resolutions
        "JPEG", // Output format
        60, // Quality
        0, // Rotation
        (uri) => {
          // Convert the base64 string to a Blob to check the size
          const byteString = atob(uri.split(",")[1]);
          const ab = new ArrayBuffer(byteString.length);
          const ia = new Uint8Array(ab);
          for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          const blob = new Blob([ab], { type: "image/jpeg" });

          // Check if the Blob size is within the limit
          if (blob.size <= 3 * 1024 * 1024) {
            // 2MB size limit
            setBackgroundImage(uri);
          } else {
            message.error(
              "Wybrana tapeta nie może zostać wystarczająco skompresowana."
            );
          }
        },
        "base64",
        3000 // Output file size in KB (2MB)
      );
    }
  };

  const handleAvatarUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setOwnerAvatar(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRandomWallpaper = () => {
    setLoading(true);
    const randomWallpaperUrl = faker.image.urlLoremFlickr({
      category: category,
      width: 720,
      height: 1600,
    });
    setBackgroundImage(randomWallpaperUrl);
    setLoading(false);
  };

  const getDeviceIcon = (deviceType) => {
    switch (deviceType) {
      case 1:
        return (
          <img
            src="/img/mobile-phone.png"
            alt="Mobile"
            style={{ width: 24, height: 24 }}
          />
        );
      case 2:
        return (
          <img
            src="/img/tablet.png"
            alt="Tablet"
            style={{ width: 24, height: 24 }}
          />
        );
      case 3:
        return (
          <img
            src="/img/laptop.png"
            alt="Laptop"
            style={{ width: 24, height: 24 }}
          />
        );
      case 4:
      default:
        return (
          <img
            src="/img/pendrive.png"
            alt="Other"
            style={{ width: 24, height: 24 }}
          />
        );
    }
  };

  const getQrCodePosition = (canvas, qrCodeImage) => {
    const positions = {
      center: [
        (canvas.width - qrCodeImage.width) / 2,
        (canvas.height - qrCodeImage.height) / 2,
      ],
      top: [(canvas.width - qrCodeImage.width) / 2, 200],
      bottom: [
        (canvas.width - qrCodeImage.width) / 2,
        canvas.height - qrCodeImage.height - 400,
      ],
      left: [0, (canvas.height - qrCodeImage.height) / 2],
      right: [
        canvas.width - qrCodeImage.width,
        (canvas.height - qrCodeImage.height) / 2,
      ],
      "top-left": [0, 0],
      "top-right": [canvas.width - qrCodeImage.width, 0],
      "bottom-left": [0, canvas.height - qrCodeImage.height],
      "bottom-right": [
        canvas.width - qrCodeImage.width,
        canvas.height - qrCodeImage.height,
      ],
    };
    return positions[qrPosition];
  };

  useEffect(() => {
    if (backgroundImage) {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.onload = () => {
        const originalWidth = 720;
        const originalHeight = 1600;
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        canvas.width = originalWidth;
        canvas.height = originalHeight;

        // Calculate the center position
        const xOffset = (canvas.width - img.width) / 2;
        const yOffset = (canvas.height - img.height) / 2;

        ctx.drawImage(img, 0, 0, originalWidth, originalHeight);

        const tempQRCodeCanvas = document.createElement("canvas");
        const qrCodeCanvas = qrCodeRef.current.children[0];
        tempQRCodeCanvas.width = qrCodeCanvas.width;
        tempQRCodeCanvas.height = qrCodeCanvas.height;
        const tempCtx = tempQRCodeCanvas.getContext("2d");

        // Fill with background color
        tempCtx.fillStyle = qrBackground;
        tempCtx.fillRect(0, 0, tempQRCodeCanvas.width, tempQRCodeCanvas.height);

        // Draw the QR code on top
        tempCtx.drawImage(qrCodeCanvas, 0, 0);

        const qrCodeImage = new Image();
        qrCodeImage.src = tempQRCodeCanvas.toDataURL();
        qrCodeImage.onload = () => {
          const [qrCodeX, qrCodeY] = getQrCodePosition(canvas, qrCodeImage);
          ctx.drawImage(qrCodeImage, qrCodeX, qrCodeY);

          // Define the two lines of text
          const textLine1 = "Skanuj kontakt do właściciela";
          const textLine2 = "Scan to contact the owner";

          ctx.font = "19px Arial";
          ctx.textAlign = "center";
          ctx.fillStyle = "black";

          // Calculate positions for text lines
          const textX = canvas.width / 2;
          const textY1 = qrCodeY + qrCodeImage.height + 50;
          const textY2 = textY1 + 50; // 50 pixels below the first line

          // Draw the owner avatar
          if (ownerAvatar) {
            const avatarImg = new Image();
            avatarImg.src = ownerAvatar;
            avatarImg.onload = () => {
              const avatarSize = 70;

              // Create a square canvas for the avatar
              const avatarCanvas = document.createElement("canvas");
              const avatarCtx = avatarCanvas.getContext("2d");
              avatarCanvas.width = avatarCanvas.height = avatarSize;

              // Calculate scale and offsets
              const scale = Math.min(
                avatarSize / avatarImg.width,
                avatarSize / avatarImg.height
              );
              const resizedWidth = avatarImg.width * scale;
              const resizedHeight = avatarImg.height * scale;
              const offsetX = (avatarSize - resizedWidth) / 2;
              const offsetY = (avatarSize - resizedHeight) / 2;

              // Draw the image on the square canvas
              avatarCtx.drawImage(
                avatarImg,
                offsetX,
                offsetY,
                resizedWidth,
                resizedHeight
              );

              // Draw the avatar image in a circular clipping region
              const avatarX = textX - avatarSize / 2; // Position at center
              const avatarY = textY1 - avatarSize / 2 + 125;
              ctx.save(); // Save the current state
              ctx.beginPath();
              ctx.arc(
                avatarX + avatarSize / 2,
                avatarY + avatarSize / 2,
                avatarSize / 2,
                0,
                2 * Math.PI
              );
              ctx.clip(); // Clip to the circle
              ctx.drawImage(avatarCanvas, avatarX, avatarY);
              ctx.restore(); // Restore the state
            };
          }

          // Calculate background widths for text lines
          const textWidth1 = ctx.measureText(textLine1).width;
          const textWidth2 = ctx.measureText(textLine2).width;

          // Draw text backgrounds
          ctx.fillStyle = "white";
          ctx.fillRect(
            textX - textWidth1 / 2 - 10,
            textY1 - 25,
            textWidth1 + 20,
            40
          );
          ctx.fillRect(
            textX - textWidth2 / 2 - 10,
            textY2 - 25,
            textWidth2 + 20,
            40
          );

          // Draw the text
          ctx.fillStyle = "black";
          ctx.fillText(textLine1, textX, textY1);
          ctx.fillText(textLine2, textX, textY2);
        };
      };
      img.src = backgroundImage;
    }
  }, [
    backgroundImage,
    qrCodeUrl,
    qrBackground,
    ownerAvatar,
    qrPosition,
    qrCodeSize,
    qrColor,
  ]); // Added ownerAvatar to dependency array

  useEffect(() => {
    if (uploading) {
      messageApi.open({
        type: "success",
        content: "Wgrywam tapetę na serwer.",
      });
    }

    if (sending) {
      messageApi.open({
        type: "success",
        content: "Wysyłam tapetę na urządzenie.",
      });
    }
  }, [sending, uploading]);

  return (
    <Modal visible={visible} onCancel={onClose} footer={null}>
      <div>
        {contextHolder}
        <Space direction="vertical" size={30} style={{ width: "100%" }}>
          {currentPlan && currentPlan.is_default ? (
            <Alert message="Pobierz tapetę i ustaw ją jako tło ekranu blokady. To ważne, bo tapeta będzie widoczna nawet przy zablokowanym ekranie, umożliwiając łatwe skanowanie kodu QR." />
          ) : (
            ""
          )}
          {device ? (
            <>
              <Card title="Urządzenie" size="small">
                <h1>
                  {getDeviceIcon(device.device_type_id)} {device.name}
                </h1>
                <p>
             
                Pobierz aplikację z <a href="https://play.google.com/store/apps/details?id=pl.cellid" target="_blank">Play Store</a> aby ustawiać tapetę zdalnie. Po pobraniu aplikacji wejdź z urządzenia na link aby skonfigurować: https://cellid.pl/....={device.uuid}{" "}
                <Button
                size="small"
                onClick={() => {
                  navigator.clipboard.writeText(`https://cellid.pl/autoconfig?deviceKey=${device.uuid}`);
                  messageApi.open({
                    type: "success",
                    content: "Skopiowane do schowka.",
                  });
                }}
              >
                Kopiuj
              </Button>
             
            </p>
              </Card>
            </>
          ) : (
            <></>
          )}
          <Card>
            {backgroundImage && (
              <div style={{ position: "relative", textAlign: "center" }}>
                <Spin spinning={loading}>
                  <canvas
                    ref={canvasRef}
                    style={{
                      maxWidth: "360px",
                      width: "auto",
                      height: "auto",
                      border: "1px solid #d9d9d9",
                      borderRadius: "4px",
                      opacity: loading ? 0.5 : 1,
                    }}
                  ></canvas>
                </Spin>
              </div>
            )}

            <Tabs
              defaultActiveKey="1"
              items={[
                {
                  key: "1",
                  label: "Tapeta",
                  children: (
                    <>
                      {" "}
                      <Space
                        direction="vertical"
                        size={10}
                        style={{ maxWidth: "250px" }}
                      >
                        <Button
                          type="primary"
                          onClick={() => handleRandomWallpaper()}
                        >
                          Wygeneruj losową tapetę
                        </Button>
                        <Select
                          value={category}
                          onChange={(value) => setCategory(value)}
                        >
                          {categories.map((cat) => (
                            <Option key={cat.value} value={cat.value}>
                              {cat.label}
                            </Option>
                          ))}
                        </Select>
                        <p>albo...</p>
                      </Space>
                      <Space
                        direction="vertical"
                        size={10}
                        style={{ maxWidth: "250px" }}
                      >
                        <label>Wybierz własne zdjęcie</label>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleBackgroundUpload}
                        />
                      </Space>
                    </>
                  ),
                },
                {
                  key: "2",
                  label: "Avatar",
                  children: (
                    <>
                      <Space
                        direction="vertical"
                        size={10}
                        style={{ maxWidth: "250px" }}
                      >
                        <label>Wybierz avatar właściciela</label>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleAvatarUpload}
                        />
                      </Space>
                    </>
                  ),
                },
                {
                  key: "3",
                  label: "Kod QR",
                  children: (
                    <>
                      <Space size={30} direction="vertical">
                        <label>Wybierz kolor tła kodu QR:</label>
                        <Radio.Group
                          onChange={(e) => setQrBackground(e.target.value)}
                          value={qrBackground}
                        >
                          <Radio value="transparent">Przeźroczysty</Radio>
                          <Radio value="white">Biały</Radio>
                        </Radio.Group>
                        <Alert message="Przeźroczyste tło najlepiej sprawdza się na jasnych tapetach, gdzie kod QR będzie dobrze widoczny." />

                        <label>Wybierz kolor kodu QR:</label>
                        <Radio.Group
                          onChange={(e) => setQrColor(e.target.value)}
                          value={qrColor}
                        >
                          <Radio value="black">Czarny</Radio>
                          <Radio value="white">Biały</Radio>
                        </Radio.Group>

                        <label>Wybierz pozycję kodu QR:</label>

                        <Radio.Group
                          value={qrPosition}
                          onChange={(e) => setQrPosition(e.target.value)}
                        >
                          <Radio value="center">Środek</Radio>
                          <Radio value="top">Góra</Radio>
                          <Radio value="bottom">Dół</Radio>
                        </Radio.Group>

                        <label>Wybierz rozmiar kodu QR:</label>
                        <Slider
                          min={100}
                          max={700}
                          step={50}
                          value={qrCodeSize}
                          onChange={setQrCodeSize}
                          tooltipVisible
                          tooltipFormatter={(value) => `${value}px`}
                          style={{ width: "100%" }}
                        />
                      </Space>
                    </>
                  ),
                },
              ]}
            />
          </Card>

          <Space direction="horizontal">
            {backgroundImage && (
              <>
                <Button
                  type="default"
                  onClick={() => downloadMergedImage(canvasRef, setLoading)}
                  disabled={loading} // Disable button when loading
                >
                  Pobierz tapetę z kodem QR
                </Button>
                <Button
                  type="default"
                  onClick={() => handleSetWallpaper(device.uuid)}
                  disabled={device.fcm_token === null} // Disable button when loading
                >
                  Wyślij tapetę na telefon
                </Button>
              </>
            )}
          </Space>

          {backgroundImage && (
            <div
              ref={qrCodeRef}
              style={{ display: "block", textAlign: "center", display: "none" }}
            >
              <QRCode
                value={qrCodeUrl}
                size={qrCodeSize}
                fgColor={qrColor}
                renderAs="canvas"
                bgColor={qrBackground}
              />
            </div>
          )}
          {currentPlan && currentPlan.is_default === true ? (
            <>
              <Card>
                <Space direction="vertical">
                  <Text>
                    Plan płatny zapewnia ważność kodu przez 12 miesięcy,
                    gwarancję działania 24 godziny na dobę, możliwość dodania
                    większej liczby urządzeń.
                  </Text>
                  <Link to="/account/plan/picker">
                    <Button type="primary">Zamów od 9,90</Button>
                  </Link>
                </Space>
              </Card>
            </>
          ) : (
            <></>
          )}
        </Space>
      </div>
    </Modal>
  );
};

export default QRCodePopup;
