import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { getAuthToken } from "../../../../helpers/account";
import { api } from "../../../../config/api";
import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe(
  "pk_live_51PFiA9P9o5jlolOrdoEQa8hSc5MKcwy9pRt2ae5zBnFD05yRr1i5VBijWKuPgOSc7B14EV6tXNyxwXWdJCrOwD1e00rmYzOloq"
);

const StripeCheckoutPopup = ({ visible, onClose, planUuid }) => {
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    if (visible && planUuid) {
      fetch(`${api.host}/payments/intent/${planUuid}`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `${getAuthToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((data) => setClientSecret(data.clientSecret));
    }
  }, [visible, planUuid]);

  const appearance = { theme: "stripe" };
  const options = { clientSecret, appearance };

  return (
    <Modal
      title="Dokończ płatność"
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </Modal>
  );
};

export default StripeCheckoutPopup;
