import React from "react";
import { Link } from "react-router-dom";
import LoginPage from "../Login/LoginPage";
import { Card, Space, Row, Col, Menu, Breadcrumb } from "antd";
import { isLogged } from "../../helpers/account";
import { UserOutlined, HomeOutlined } from "@ant-design/icons";

function AccountPage() {
  if (isLogged()) {
    const menuItems = [
      {
        key: "address-data",
        label: "Numer telefonu i adres e-mail",
        link: "/account/data",
      },
    ];

    return (
      <>
        <Row
          style={{
            minHeight: "100vh",
          }}
        >
          <Col
            span={24}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Space size={15} direction="vertical">
              <Breadcrumb
                items={[
                  {
                    href: "/devices",
                    title: <HomeOutlined />,
                  },
                  {
                    title: "Moje Dane",
                  },
                ]}
              />

              <Card
                title={
                  <>
                    <img src="/img/id.png" /> Moje dane
                  </>
                }
              >
                <Menu
                  style={{
                    width: 256,
                    borderInlineEnd: 0,
                  }}
                >
                  {menuItems.map((item) => (
                    <Menu.Item key={item.key}>
                      <Link to={item.link}>{item.label}</Link>
                    </Menu.Item>
                  ))}
                </Menu>
              </Card>
            </Space>
          </Col>
        </Row>
      </>
    );
  } else {
    return <LoginPage></LoginPage>;
  }
}

export default AccountPage;
