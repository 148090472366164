import React from "react";
import { Card, Space, Typography, Button, Tag } from "antd";

const { Title, Text } = Typography;

const PlanCard = ({ plan, handleSelectPlan, showCta }) => {
  return (
    <Card
      title={
        <>
          
          {plan.isPopular && (<><Tag color="gold" style={{padding:"5px"}}>Dobry stosunek ceny do wartości!</Tag></>)}
        </>
      }
    >
      <Space direction="vertical">
        <Title level={3}>{plan.title}</Title>
        {plan.features.map((feature, index) => (
          <Text key={index} className="planPickerItemDescription">
            <img src="/img/check.png" alt="Check" /> {feature}
          </Text>
        ))}
        <Title level={3}>{plan.price}</Title>
        {showCta === true ? (
          <Button type="primary" onClick={() => handleSelectPlan(plan.uuid)}>Wybierz</Button>
        ) : ""}
      </Space>
    </Card>
  );
};

export default PlanCard;
