import {
  Button,
  Input,
  Modal,
  Radio,
  Space,
  Row,
  Col,
  Typography,
  Divider,
} from "antd";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import OfferTestPopup from "./OfferTestPopup";
import ComparisonTable from "./ComparisionTable";
const { Title } = Typography;

const LocationPage = () => {
  const [visible, setVisible] = useState(null);
  const [deviceType, setDeviceType] = useState(1);
  const [deviceName, setDeviceName] = useState("");

  const handleCalendlyClick = (event) => {
    event.preventDefault();
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url: "https://calendly.com/smieciappka?hide_gdpr_banner=1",
      });
    } else {
      console.error("Calendly is not loaded.");
    }
  };

  const handleAddDevice = () => {
    const deviceData = {
      name: deviceName,
      type: deviceType,
    };

    localStorage.setItem("deviceData", JSON.stringify(deviceData));

    logGAEvent("click", {
      event_category: "engagement",
      event_label: headingVariant,
      value: 1,
    });

    window.location.href = "/account/account/register";
  };

  const headingVariants = [
    "Pobierz tapetę na ekran blokady z kodem QR do kontaktu w razie zguby",
  ];

  const getHeadingVariant = () => {
    const storedVariant = localStorage.getItem("headingVariant");
    if (storedVariant) {
      return storedVariant;
    } else {
      const randomVariant =
        headingVariants[Math.floor(Math.random() * headingVariants.length)];
      localStorage.setItem("headingVariant", randomVariant);
      return randomVariant;
    }
  };

  const headingVariant = getHeadingVariant();

  const logGAEvent = (eventName, eventParams) => {
    if (window.gtag) {
      window.gtag("event", eventName, eventParams);
    }
  };

  const questions = [
    {
      key: "1",
      label: "Czy muszę zapłacić za zarejestrowanie mojego urządzenia",
      content: (
        <p>
          Nie, rejestracja urządzenia w systemie CellID jest całkowicie darmowa.
          Zachęcamy do rejestrowania wszystkich swoich urządzeń, aby zwiększyć
          szanse na ich odzyskanie w przypadku zagubienia.
        </p>
      ),
    },
    {
      key: "2",
      label: "Czy system działa na wszystkie rodzaje urządzeń?",
      content: (
        <p>
          Tak, system CellID działa na wszystkie rodzaje urządzeń, w tym
          telefony, tablety, laptopy i inne. Niezależnie od rodzaju urządzenia,
          możesz je zarejestrować i korzystać z naszych usług.
        </p>
      ),
    },
    {
      key: "3",
      label: "Jakie są opcje odzyskania mojego urządzenia?",
      content: (
        <p>
          Jeśli Twoje urządzenie zostanie znalezione przez uczciwego znalazcę,
          będzie mógł on skontaktować się z Tobą za pośrednictwem danych
          kontaktowych podanych w systemie CellID.
        </p>
      ),
    },
    {
      key: "4",
      label:
        "Co się stanie, jeśli moje urządzenie zostanie znalezione przez kogoś nieuczciwego?",
      content: (
        <p>
          Niestety, nie możemy zagwarantować, że każde urządzenie zostanie
          zwrócone, zwłaszcza jeśli trafi w ręce osoby nieuczciwej. Jednakże
          rejestracja w systemie CellID zwiększa szanse na odzyskanie
          urządzenia, ponieważ uczciwy znalazca będzie miał możliwość łatwego
          skontaktowania się z właścicielem.
        </p>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          CellID - Łączymy urządzenia mobilne z kontaktem do właściciela
        </title>
      </Helmet>

      <Row justify="space-between" align="middle" gutter={12}>
        <Col md={4}></Col>
        <Col md={8} style={{ padding: "0px 30px" }}>
          <Space direction="vertical">
            <Title>
              Jeśli zgubisz swój telefon to on do Ciebie wróci, dzięki nam.
            </Title>

            <p>
              Tapeta na ekran blokady z kodem QR zawierającym aktualne dane do
              kontaktu + lokalizacja telefonu w trybie rzeczywistym. Dla tych,
              którzy dbają o swoje dane i wspomnienia.
            </p>
            <Space>
              <a href="/account/register">
                <Button size="large" type="primary">
                  Załóż darmowe konto
                </Button>
              </a>
              <a
                href="https://cellid.pl/device/744adf3f-2756-4216-b122-04092257f0f4"
                target="_blank"
              >
                <Button size="large">Zobacz demo</Button>
              </a>
            </Space>
          </Space>
        </Col>
        <Col md={8}>
          <img src="/img/how.png" style={{ width: "100%" }} />
        </Col>
        <Col md={4}></Col>
      </Row>
      <div className="hero-background-1">
        <Row justify="space-between" align="top" gutter={12}>
          <Col md={4}></Col>
          <Col md={16} style={{ padding: "0px 30px", textAlign: "center" }}>
            <Space>
              <img src="/img/teachers.png" style={{ width: "100px" }} />
              <p>Zatwierdzona przez nauczycieli</p>
              <a
                href="https://play.google.com/store/apps/details?id=pl.cellid"
                target="_blank"
                rel="noreferrer"
                title="Link do pobrania CELLID w Google Play"
              >
                <img
                  src="/img/google-play-badge.png"
                  style={{ width: "100px" }}
                  alt="CELLID w Google Play"
                />
              </a>
            </Space>
          </Col>

          <Col md={4}></Col>
        </Row>
      </div>
      <div className="hero-background">
        <Row justify="space-between" align="top" gutter={12}>
          <Col md={4}></Col>
          <Col md={8} style={{ padding: "30px" }}>
            <Space direction="vertical">
              <img
                src="/img/shocked-removebg-preview.png"
                style={{ height: "250px" }}
              />
              <Title level={2} style={{ color: "#fff" }}>
                Jak przeważnie wygląda zagubienie telefonu?
              </Title>
              <p style={{ color: "#fff" }}>
                Zgubiłeś telefon. Najpierw nerwowo przeszukałeś kieszenie,
                plecak, torbę – ale nigdzie go nie było. Ostatni raz korzystałeś
                z niego w pociągu. Dzwoniłeś na swój numer, ale nikt nie
                odbierał. Miałeś ekran blokady zabezpieczony PIN-em, więc nawet
                jeśli ktoś znalazł telefon, nie mógł się z Tobą skontaktować. W
                końcu telefon przepadł na dobre. Straciłeś nie tylko urządzenie,
                ale też wszystkie zdjęcia z wakacji, notatki, kontakty –
                wszystko, co było dla Ciebie ważne.
              </p>
            </Space>
          </Col>
          <Col md={8} style={{ padding: "30px" }}>
            <Space direction="vertical">
              <img
                src="/img/calm-removebg-preview.png"
                style={{ height: "250px" }}
              />
              <Title level={2} style={{ color: "#fff" }}>
                Jak wygląda zagubienie z aplikacją CELLID?
              </Title>
              <p style={{ color: "#fff" }}>
                Jesteś spokojny, bo dzięki aplikacji CELLID osoba, która
                znalazła telefon, mogła się z Tobą łatwo skontaktować, mimo
                zablokowanego ekranu. Dodatkowo otrzymałeś powiadomienie SMS na
                alternatywny numer, dzięki czemu mogłeś natychmiast zareagować.
                Odzyskałeś wszystkie dane – zdjęcia, dokumenty, kontakty. Dzięki
                CELLID telefon wrócił do Ciebie szybko i bez problemu.
              </p>
            </Space>
          </Col>
          <Col md={4}></Col>
          <Col md={4}></Col>
          <Col md={8} style={{ padding: "30px", textAlign: "center" }}>
           
            <Title level={3} style={{ color: "#fff" }}>
              <i>"...CELLID jest jak ubezpieczenie telefonu - nie poczujesz tej
              wartości od razu ale gwarantujemy, że poczujesz ją w momencie
              utraty urządzenia..."</i>
            </Title>
          </Col>
          <Col md={4}></Col>
        </Row>
      </div>
      <div className="hero-background-2">
        <Row justify="space-between" align="top" gutter={12}>
          <Col md={4}></Col>
          <Col md={16} style={{ padding: "0px 30px", textAlign: "center" }}>
            <Space direction="vertical">
              <Title level={1}>
                Twoje wspomnienia i dane są bezcenne, a telefon kosztowny –
                zadbaj o ich bezpieczeństwo!
              </Title>
              <Divider></Divider>
              <Space>
                <div>
                  <Space direction="vertical">
                    <img src="/img/2622093.png" style={{ width: "100px" }} />
                    <Title level={3}>Utracone dane</Title>
                    <p>
                      Strata telefonu oznacza utratę cennych informacji –
                      ważnych notatek, kontaktów i dokumentów, które mogą być
                      trudne do odzyskania.
                    </p>
                  </Space>
                </div>
                <div>
                  <Space direction="vertical">
                    <img src="/img/1076818.png" style={{ width: "100px" }} />
                    <Title level={3}>Zaginione zdjęcia</Title>
                    <p>
                      Twoje wspomnienia, takie jak zdjęcia z wakacji, mogą
                      zniknąć na zawsze, jeśli telefon nie wróci do Ciebie.
                    </p>
                  </Space>
                </div>
                <div>
                  <Space direction="vertical">
                    <img src="/img/5501384.png" style={{ width: "100px" }} />
                    <Title level={3}>Dodatkowy koszt</Title>
                    <p>
                      Telefon może być drogi do zastąpienia, a zakup nowego
                      oznacza dodatkowe wydatki i kłopoty.
                    </p>
                  </Space>
                </div>
                <Divider></Divider>
              </Space>
              <div style={{margin:"50px 0"}}>
                <Title level={2}>
                  Aplikacja CELLID rozwiązuje ten problem w unikalny i prosty
                  sposób – generuje wizytówkę urządzenia z kodem QR, który
                  wyświetla się na tapecie ekranu blokady.
                </Title>
                <p>
                  + opcja lokalizowania telefonu w trybie live z dokładnością do
                  10 metrów
                </p>

                <Space>
                  <a href="/account/register">
                    <Button size="large" type="primary">
                      Załóż darmowe konto
                    </Button>
                  </a>
                  <a
                    href="https://cellid.pl/device/744adf3f-2756-4216-b122-04092257f0f4"
                    target="_blank"
                  >
                    <Button size="large">Zobacz demo</Button>
                  </a>
                </Space>
              </div>
            </Space>
          </Col>

          <Col md={4}></Col>
        </Row>
        <Divider></Divider>
        <Space size={30} direction="vertical">
        <center>
          <Title level={2}>
            Generator tapet z gotowymi zdjęciami. Tapeta z własnym zdjęciem?
            Tak!
          </Title>
          <p>
            Nie będziesz marnować niepotrzebne czasu. Dodaj urządzenie, wybierz
            tapetę i ustaw. Tylko tyle, żeby zabezpieczyć swój telefon.
          </p>
        </center>
        <Row justify="space-between" align="top" gutter={12}>
          <Col xs={4}>
            <img
              src="/img/Screenshot_20240821_234719_One UI Home.jpg"
              style={{ width: "100%", borderRadius: "10px" }}
            />
          </Col>
          <Col xs={4}>
            <img
              src="/img/Screenshot_20240821_234749_One UI Home.jpg"
              style={{ width: "100%", borderRadius: "10px" }}
            />
          </Col>
          <Col xs={4}>
            <img
              src="/img/Screenshot_20240821_235115_One UI Home.jpg"
              style={{ width: "100%", borderRadius: "10px" }}
            />
          </Col>
          <Col xs={4}>
            <img
              src="/img/Screenshot_20240821_234959_One UI Home.jpg"
              style={{ width: "100%", borderRadius: "10px" }}
            />
          </Col>
          <Col xs={4}>
            <img
              src="/img/Screenshot_20240821_234820_One UI Home.jpg"
              style={{ width: "100%", borderRadius: "10px" }}
            />
          </Col>
          <Col xs={4}>
            <img
              src="/img/Screenshot_20240821_234643_One UI Home.jpg"
              style={{ width: "100%", borderRadius: "10px" }}
            />
          </Col>
        </Row>
        </Space>
      </div>
      <br />
      <div className="hero-background-2">
        <Row justify="space-between" align="top" gutter={12}>
          <Col md={4}></Col>
          <Col md={16} style={{ padding: "0px 30px", textAlign: "center" }}>
            <Space direction="vertical">
              <img src="/img/47844.png" style={{ width: "100px" }} />
              <Title level={3}>
                Porównaj opcje CELLID z innymi metodami zabezpieczania telefonu
              </Title>
              <Space>
                <img src="/img/Screenshot from 2024-08-16 00-01-01.png" />
                <p>
                  PS. CELLID zdobył mnóstwo (dla nas to coś!) łapek i serduszek
                  już w pierwszym dniu działania oraz zyskał wiele pozytywnych
                  komentarzy!
                </p>
              </Space>

              <div className="compare">
                <p>
                  Większość rozwiązań służy właścicielowi do lokalizowania
                  urządzenia. CELLID umożliwia osobie, która znalazła
                  urządzenie, skontaktowanie się z właścicielem.
                </p>

                <ComparisonTable />
              </div>
            </Space>
          </Col>

          <Col md={4}></Col>
        </Row>
      </div>
      <br />
      <Row justify="space-between" align="middle" gutter={12}>
        <Col md={4}></Col>
        <Col md={16} style={{ textAlign: "center" }}>
          <Title level={2}>
            Rozszerz opcję tapety na ekran blokady z kodem QR o lokalizację z
            dokładnością do 10 metrów w czasie rzeczywistym.
          </Title>
        </Col>
        <Col md={4}></Col>
        <Col md={4}></Col>
        <Col md={8}>
          <img src="/img/27735632.png" style={{ width: "100%" }} />
        </Col>
        <Col md={8} style={{ padding: "0px 30px" }}>
          <Space direction="vertical">
            <Title level={2}>Chcesz znać dokładną pozycję telefonu?</Title>
            <Title level={4}>
              CELLID potrafi określać pozycję telefonu z dokładnością do 10
              metrów, w czasie rzeczywistym, łącznie z poziomem naładowania
              baterii. Możesz używać tej funkcji do śledzenia pozycji swojego
              urządzenia, domowników i współpracowników, co ułatwia koordynację
              i bezpieczeństwo.
            </Title>
            <Space>
              <a href="/account/register">
                <Button size="large" type="primary">
                  Załóż darmowe konto
                </Button>
              </a>

              <a
                href="https://play.google.com/store/apps/details?id=pl.cellid"
                target="_blank"
                className="Button"
              >
                <Button size="large">Pobierz z Google Play</Button>
              </a>
            </Space>
          </Space>
        </Col>

        <Col md={4}></Col>
      </Row>
      <Divider></Divider>
      <Row justify="space-between" align="middle" gutter={12}>
        <Col md={4}></Col>
        <Col md={8}>
          <img
            src="/img/pngtree-global-data-security-personal-data-security-cyber-data-security-online-concept-png-image_7255044.png"
            style={{ width: "100%" }}
          />
        </Col>
        <Col md={8} style={{ padding: "0px 30px" }}>
          <Space direction="vertical">
            <Title level={2}>
              Dbasz o bezpieczeństwo urządzeń i danych w firmie?
            </Title>
            <Title level={4}>
              Aplikacja CELLID dla telefonów z Androidem pozwala na zdalną
              aktualizację tapety blokady ekranu. Możesz łatwo i masowo
              aktualizować telefony pracowników, zapewniając im dodatkową
              ochronę w razie zagubienia telefonu.
            </Title>
            <Space>
              <a href="/account/register">
                <Button size="large" type="primary">
                  Załóż darmowe konto
                </Button>
              </a>
              <a href="/" onClick={handleCalendlyClick}>
                <Button size="large">Umów spotkanie</Button>
              </a>
            </Space>
          </Space>
        </Col>

        <Col md={4}></Col>
      </Row>
      <Divider></Divider>

      <Row justify="space-between" align="middle" gutter={12}>
        <Col md={4}></Col>
        <Col md={16} style={{ padding: "0px 30px", textAlign: "center" }}>
          <Space direction="vertical">
            <img src="/img/happy.png" style={{ width: "200px" }} />
            <Title level={2}>
              Niezależnie od tego, czy jesteś w drodze na wakacje, codziennie
              dojeżdżasz do biura, czy po prostu lubisz spacery na świeżym
              powietrzu, aplikacja CELLID sprawia, że utrata telefonu jest mniej
              stresująca i zwiększa szanse na jego odzyskanie.
            </Title>

            <Space>
              <a href="/account/register">
                <Button size="large" type="primary">
                  Załóż darmowe konto
                </Button>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=pl.cellid"
                target="_blank"
                className="Button"
              >
                <Button size="large">Pobierz z Google Play</Button>
              </a>
            </Space>
            <div className="howOtherUse">
              <Title level={2}>Opinie i reakcje</Title>

              <div className="howOtherUse_personas">
                <div className="howOtherUse_persona_1">
                  <p className="howOtherUse_nick">
                    <img src="/img/10.svg" alt="Aśka" /> Aśka
                  </p>
                  <p> 👏👏👏</p>
                </div>
                <div className="howOtherUse_persona_2">
                  <p className="howOtherUse_nick">
                    <img src="/img/11.svg" alt="Yoram" /> Yoram
                  </p>
                  <p>
                    Looks like a great idea. Apple Find My and Android Find My
                    Device is for the owner to locate their device. Not for
                    someone else to notify you. Bravo!
                  </p>
                </div>
              </div>
              <div className="howOtherUse_personas">
                <div className="howOtherUse_persona_3">
                  <p className="howOtherUse_nick">
                    <img src="/img/12.svg" alt="Przemek" /> Przemek
                  </p>
                  <p>
                    Pomysł spoko! Można pójść szerzej, z naklejkami na wszystko,
                    nie tylko telefon 🙂
                  </p>
                </div>
                <div className="howOtherUse_persona_3">
                  <p className="howOtherUse_nick">
                    <img src="/img/13.svg" alt="Peter" /> Peter
                  </p>
                  <p>Spoko. 🙂</p>
                </div>
              </div>
            </div>
          </Space>
        </Col>
        <Col md={4}></Col>
      </Row>
      <br />

      <Divider></Divider>
      <center>
        <Space direction="vertical">
          <center>
            <Title level={3}>Najczęściej zadawane pytania</Title>
          </center>
          <div className="questions">
            {questions.map((question) => (
              <div key={question.key} style={{ marginBottom: "16px" }}>
                <a
                  href="#"
                  style={{ width: "300px", overflowX: "hidden" }}
                  onClick={(e) => {
                    e.preventDefault();
                    setVisible(question.key);
                  }}
                >
                  {question.label}
                </a>
                <Modal
                  title={question.label}
                  visible={visible === question.key}
                  onCancel={() => setVisible(null)}
                  footer={[
                    <Button key="close" onClick={() => setVisible(null)}>
                      Zamknij
                    </Button>,
                  ]}
                >
                  {question.content}
                </Modal>
              </div>
            ))}
            <a href="/ubezpieczenie-telefonu">
              Kompleksowy przewodnik po ubezpieczeniu telefonu
            </a>
          </div>
        </Space>
      </center>

      <div className="ceo">
        <p>
          {" "}
          Chcę minimalizować ryzyko utraty Twoich urządzeń, umożliwiając szybkie
          i łatwe odzyskanie, łącząc znalazców z właścicielami urządzeń. Wybierz
          CELLID i ciesz się spokojem, wiedząc, że Twoje urządzenia są
          bezpieczne.
        </p>
        <div className="photo">
          <img src="/img/3.svg" alt="CEO" />
        </div>
        <div className="sign">
          <img src="/img/4.svg" alt="Signature" />
        </div>
        <div className="postpay">Twórca CELLID</div>
      </div>
      <div className="partners">
        <img src="/img/14.svg" alt="Partners" />
      </div>
      <OfferTestPopup />
    </>
  );
};

export default LocationPage;
