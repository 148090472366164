import React from "react";
import LoginPage from "../../../Login/LoginPage";
import { Space, Row, Col, Breadcrumb, Typography, Card, Button } from "antd";
import { isLogged } from "../../../../helpers/account";
import { HomeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const { Title, Text } = Typography;

function PaymentThankyouPage() {
  if (isLogged()) {
    return (
      <>
        <Row
          style={{
            marginTop: "30px",
            marginBottom: "30px",
          }}
        >
          <Col
            span={24}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Space direction="vertical" size={30}>
              <Breadcrumb
                items={[
                  {
                    href: "/devices",
                    title: <HomeOutlined />,
                  },
                  {
                    href: "/account/plan",
                    title: "Bieżący plan",
                  },
                  {
                    title: "Potwierdzenie płatności",
                  },
                ]}
              />
              <Card style={{ maxWidth: "500px" }}>
                <Title level={3} style={{display:"flex"}}>Gratulujemy wyboru! <img src="/img/celebration.png" /></Title>
                <Text>
                  Dziękujemy za zaufanie, jakim obdarzyłeś nasze usługi.
                  Niedługo przełączymy Twoje konto na wyższy plan, abyś mógł
                  cieszyć się jeszcze większymi korzyściami.
                  <br />
                  <br />
                  Prosimy, upewnij się, że dane kontaktowe w zakładce "
                  <Link to="/account/data">Dane teleadresowe</Link>" są
                  aktualne, ponieważ wyślemy na nie naklejki z kodami QR.
                  <br />
                  <br />
                  Dziękujemy, że wybrałeś właśnie nas!
                </Text>
                <br/><br/>
                <div>
                  <Link to="/devices">
                    <Button>Powrót</Button>
                  </Link>
                </div>
              </Card>
            </Space>
          </Col>
        </Row>
      </>
    );
  } else {
    return <LoginPage></LoginPage>;
  }
}

export default PaymentThankyouPage;
